import { InfiniteScroll, Popover, PullToRefresh } from 'antd-mobile';
import { sleep } from 'antd-mobile/es/utils/sleep';
import { request } from 'api';
import { FilterIcon } from 'assets/images';
import BaseTitle from 'components/BaseTitle';
import { useWindowHeight } from 'hooks/useWindowSize';
import { UserInfoPopupProps } from 'pages/User/types';
import { useEffect, useMemo, useRef, useState } from 'react';
import OrderCard from '../OrderCard';
import './TransferRecord.less';
export type TransferRecordType = {
  creatorName: string;
  creatorTime: string;
  nftFile: string;
  nftHash: string;
  nftId: string;
  nftInfoId: string;
  nftName: string;
  nftProtocolId: string;
  nftSeriesName: string;
  nftType: number;
  nickName: null;
  ownerId: string;
  price: number;
  publicTime: string;
  transactionStatus: number;
  transferAddress: string;
  transferFilter: number;
  transferMobile: string;
  transferName: string;
  transferTime: string;
  transferType: number;
};
const actions = [
  { key: 100, text: '全部' },
  { key: 0, text: '转赠他人' },
  { key: 1, text: '收到转赠' },
  { key: 2, text: '转赠失败' },
  { key: 3, text: '转赠中' },
];
enum TransferFilterColor {
  'transfer-other',
  'finished',
  'error',
  'going',
}
type TransferStatus = {
  transactionStatus?: number;
  transferType?: number;
};
const list = [
  {
    label: '所属系列',
    name: 'nftSeriesName',
  },
  {
    label: '发行机构',
    name: 'creatorName',
  },
  {
    label: '发行时间',
    name: 'publicTime',
  },
  {
    label: '藏品ID',
    name: 'nftId',
  },
  {
    label: '藏品哈希',
    name: 'nftHash',
    isCopy: true,
  },
];

const receiveOption = [
  {
    label: '转赠时间',
    name: 'transferTime',
  },
  {
    label: '受赠人',
    name: 'transferName',
  },
  {
    label: '受赠地址',
    name: 'transferAddress',
    isCopy: true,
  },
  {
    label: '受赠手机号',
    name: 'transferMobile',
    isCopy: true,
  },
];
const transferOption = [
  {
    label: '受赠时间',
    name: 'transferTime',
  },
  {
    label: '赠送人',
    name: 'transferName',
  },
  {
    label: '赠送地址',
    name: 'transferAddress',
    isCopy: true,
  },
  {
    label: '赠送手机号',
    name: 'transferMobile',
    isCopy: true,
  },
];

export default function TransferRecord({ onClose }: UserInfoPopupProps) {
  const recordRef = useRef<any>();
  const [filter, setFilter] = useState<number>(100);
  const filterExtra = useMemo(
    () => (
      <Popover.Menu
        className="user-record-popover"
        getContainer={() => recordRef?.current}
        actions={actions.map((action) => ({
          ...action,
          icon: null,
        }))}
        onAction={(node) => {
          const { key } = node as { key: number };
          setFilter(key);
        }}
        placement="bottom-start"
        trigger="click">
        <div className="flex-align-center filter-extra-wrapper">
          <FilterIcon />
          {/* 全部 */}
          {actions.map((item) => {
            if (item.key === filter) {
              return item.text;
            }
          })}
        </div>
      </Popover.Menu>
    ),

    [filter],
  );
  const listOption = (item: any) => {
    return list.concat(item?.transferFilter === 1 ? transferOption : receiveOption);
  };
  return (
    <div ref={recordRef} className="content-bg-color transfer-record-wrapper">
      <BaseTitle title={'转赠记录'} extra={filterExtra} isFixedTop goBack={onClose} />
      <OrderCard
        listOption={listOption}
        type="TransferRecord"
        actions={actions}
        statusColor={TransferFilterColor}
        request={request.user.TRANSFER_RECORD}
        filter={filter}
      />
    </div>
  );
}
