import './UserRecord.less';
import { WatchListRecord as WatchListRecordIcon, OrderIcon, TransferRecord as TransferRecordIcon } from 'assets/images';
import { useCallback, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Popup } from 'antd-mobile';
import OrderRecord from '../OrderRecord';
import WatchList from '../WatchList';
import TransferRecord from '../TransferRecord';
import { useUser } from 'contexts/useUser';
import { WeChatPersonType } from 'contexts/useUser/hooks';
import { useLocalStorage } from 'react-use';
import storages from 'storages';
import { useModalDispatch } from 'contexts/useModal/hooks';

// element: <OrderRecord />,
// element: <WatchList />,

// element: <TransferRecord />,

const recordList = [
  {
    key: 'order',
    icon: <OrderIcon />,
    text: '订单记录',
    link: '/user/order-record',
  },
  {
    key: 'watchList',
    icon: <WatchListRecordIcon />,
    text: '关注列表',
    link: '/user/watch-list',
  },
  {
    key: 'transferRecord',
    icon: <TransferRecordIcon />,
    text: '转赠记录',
    link: '/user/transfer-record',
  },
];

export default function UserRecord() {
  const nav = useNavigate();
  const { setPhoneModal } = useModalDispatch();
  const [popupType, setPopupType] = useState<string>();
  const [{ phoneNumber, openid }] = useUser();
  const [weChatPerson] = useLocalStorage<WeChatPersonType>(storages.weChatPerson);
  const recordClick = (item: any) => {
    if (!phoneNumber) return setPhoneModal(true);
    nav(item.link);
  };
  return (
    <>
      <ul className="flex-between-center user-record-wrapper">
        {recordList.map((item) => (
          <li key={item.key} onClick={() => recordClick(item)}>
            {item.icon}
            <br />
            {item.text}
          </li>
        ))}
      </ul>

      {/* <Popup
        visible={!!popupType}
        getContainer={null}
        mask={false}
        position="right"
        className="user-popup-wrapper"
        bodyStyle={{ width: '100vw' }}
        destroyOnClose>
        {popupType === 'order' ? <OrderRecord onClose={() => setPopupType(false)} /> : null}
        {popupType === 'watchList' ? <WatchList onClose={() => setPopupType(false)} /> : null}
        {popupType === 'transferRecord' ? <TransferRecord onClose={() => setPopupType(false)} /> : null}
      </Popup> */}
    </>
  );
}
