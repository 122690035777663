export enum NftStateEnum {
  待上架 = 0,
  上链中 = 1,
  待售中 = 2,
  可售 = 3,
  已下架 = 4,
  已售罄 = 5,
}

export enum SendCodeEnum {
  绑定手机号 = '1',
  转赠 = '3',
  验证旧手机号 = '4',
}

export enum NftType {
  '图片' = 0,
  '音频' = 1,
  '视频' = 2,
  '3D模型' = 3,
}

export enum ProductType {
  '藏品' = 0,
  '盲盒' = 1,
}

export enum PaymentType {
  微信支付,
  免费领取,
  兑换码,
}
