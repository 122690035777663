import { Button } from 'antd-mobile';
import { CloseOutline } from 'antd-mobile-icons';
import clsx from 'clsx';
import { loginRedirect } from 'components/Modal/LoginModal/LoginModal';
import wechatInfo from 'constants/wechatInfo';
import { useModal } from 'contexts/useModal';
import { useModalDispatch } from 'contexts/useModal/hooks';
import { useUser } from 'contexts/useUser';
import { useGetToken, WeChatPersonType } from 'contexts/useUser/hooks';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDebounce, useLocalStorage, useSearchParam } from 'react-use';
import storages from 'storages';

import './style.less';
const env = process.env.REACT_APP_ENV;
enum tipStatusEnum {
  '未绑定手机号' = -1,
  '未登录' = 0,
  '未实名' = 1,
  '均完成' = 2,
}
const tipButtonContent: any = {
  未绑定手机号: '去绑定',
  未登录: '登录',
  未实名: '实名认证',
};
const tipContent: any = {
  未绑定手机号: '绑定手机号码，完成账号认证',
  未登录: '登录海河数藏，开启你的数字藏品之旅',
  未实名: '完成实名认证，抢购快人一步',
};

export default function LoginTip() {
  const wxCode = useSearchParam('code');
  const wxState = useSearchParam('state');
  const { setVerifiedModal } = useModalDispatch();
  const [accountInfo] = useLocalStorage(storages.accountInfo);
  const [tipStatus, setTipStatus] = useState<number>(2);
  const [{ id, nickName, isVerifiedName, openid, phoneNumber }] = useUser();
  const [weChatPerson] = useLocalStorage<WeChatPersonType>(storages.weChatPerson);
  const nav = useNavigate();
  const getToken = useGetToken();
  const ua = navigator.userAgent;
  const isWechat = ua.includes('MicroMessenger');

  // useEffect(() => {
  //   let flag = 0;
  //   if (isVerifiedName) {
  //     flag = 2;
  //   } else if (phoneNumber) {
  //     flag = 1;
  //   } else if (weChatPerson?.openid || openid) {
  //     flag = -1;
  //   }

  //   setTipStatus(flag);
  // }, [id, isVerifiedName, openid, phoneNumber, weChatPerson?.openid, wxCode, wxState]);
  useDebounce(
    () => {
      let flag = 0;
      if (isVerifiedName) {
        flag = 2;
      } else if (phoneNumber) {
        flag = 1;
      } else if (weChatPerson?.openid || openid) {
        flag = -1;
      }
      setTipStatus(flag);
    },
    1500,
    [id, isVerifiedName, openid, phoneNumber, weChatPerson?.openid, wxCode, wxState],
  );
  useEffect(() => {
    if ((window as any).loginTip === 'hidden') {
      setTipStatus(2);
    }
  }, []);

  return (
    <div className={clsx('login-tip', tipStatus === 2 && 'hidden-tip')}>
      {/* tipStatusEnum[tipStatus] */}
      <div className="content">{tipStatus < 2 && tipContent[tipStatusEnum[tipStatus]]}</div>
      <div className="action">
        <Button
          shape="rounded"
          onClick={() => {
            // const flag = accountInfo && isVerifiedName ? false : true;
            // setTipStatus(2);
            if (tipStatus === 0) {
              if (isWechat) {
                loginRedirect();
              } else {
                nav('/login?type=login');
              }
              // loginRedirect();
              //   setTipStatus(1);
            }
            if (tipStatus === 1) {
              // nav('/user/user-info');
              setVerifiedModal(true);
            }
            if (tipStatus === -1) {
              nav('/login?type=phone');
            }
          }}>
          {tipButtonContent[tipStatusEnum[tipStatus]]}
          {/* {tipStatus === 0 ? '登录' : '实名认证'} */}
        </Button>
        <span>
          <CloseOutline
            onClick={() => {
              (window as any).loginTip = 'hidden';
              setTipStatus(2);
            }}
          />
        </span>
      </div>
    </div>
  );
}
