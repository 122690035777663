import './SettingList.less';
import {
  AboutUs as AboutUsIcon,
  ContactUs as ContactUsIcon,
  UserInfoIcon,
  RedemptionCode as RedemptionCodeIcon,
  Logout,
} from 'assets/images';
import { useState } from 'react';
import AboutUs from '../AboutUs';
import ContactUs from '../ContactUs';
import RedemptionCode from '../RedemptionCode';
import { Popup } from 'antd-mobile';
import UserInfo from '../UserInfo/UserInfo';
import CommonModal from 'components/Modal/CommonModal/CommonModal';
import { useUserDispatch, WeChatPersonType } from 'contexts/useUser/hooks';
import { useLocalStorage } from 'react-use';
import storages from 'storages';
import { useUser } from 'contexts/useUser';
import { useModalDispatch } from 'contexts/useModal/hooks';
import Cookies from 'js-cookie';

enum UserListPage {
  Popup = 'Popup',
  Modal = 'Modal',
}

const userSettingList = [
  {
    key: 'userInfo',
    // link: '/user/user-info',
    type: UserListPage.Popup,
    icon: <UserInfoIcon />,
    text: '个人资料',
  },
  {
    key: 'contactUs',
    // link: '/user/contactUs',
    icon: <ContactUsIcon />,
    type: UserListPage.Popup,
    text: '联系我们',
  },
  {
    key: 'aboutUs',
    // link: '',
    icon: <AboutUsIcon />,
    type: UserListPage.Popup,
    text: '关于我们',
  },
  {
    key: 'redemptionCode',
    // link: '',
    icon: <RedemptionCodeIcon />,
    type: UserListPage.Popup,
    text: '兑换码',
  },
  {
    key: 'logout',
    // link: '',
    icon: <Logout />,
    type: UserListPage.Modal,
    text: '退出登录',
  },
];
export default function SettingList() {
  const [popupType, setPopupType] = useState<string | undefined | boolean>(false);
  const [ModalType, setModalType] = useState<string | boolean>(false);
  const dispatch = useUserDispatch();
  const [, , remover] = useLocalStorage(storages.accountInfo);
  const [, , removerWechat] = useLocalStorage<WeChatPersonType>(storages.weChatPerson);
  const [{ phoneNumber }] = useUser();
  const { setPhoneModal } = useModalDispatch();
  return (
    <>
      <ul className="user-setting-list-wrapper">
        {userSettingList.map((item) => (
          <li
            className="flex-align-center"
            key={item.key}
            onClick={() => {
              // item?.link && navigate(item.link);
              if (item.key === 'redemptionCode' && !phoneNumber) return setPhoneModal(true);
              item?.type === UserListPage.Popup && setPopupType(item.key);
              item?.type === UserListPage.Modal && setModalType(item.key);
            }}>
            {item.icon}
            {item.text}
          </li>
        ))}
      </ul>
      <Popup
        visible={!!popupType}
        getContainer={null}
        mask={false}
        position="right"
        className="user-popup-wrapper"
        bodyStyle={{ width: '100vw' }}>
        {/* {mockContent} */}
        {popupType === 'aboutUs' && <AboutUs onClose={() => setPopupType(false)} />}
        {popupType === 'contactUs' && <ContactUs onClose={() => setPopupType(false)} />}
        {popupType === 'redemptionCode' && <RedemptionCode onClose={() => setPopupType(false)} />}
        {popupType === 'userInfo' && <UserInfo onClose={() => setPopupType(false)} />}
      </Popup>
      <CommonModal
        visible={!!ModalType}
        actions={[
          [
            { key: 'cancel', text: '取消', className: 'cancel', onClick: () => setModalType(false) },
            {
              key: 'logout',
              text: '退出登录',
              className: 'logout',
              onClick: () => {
                remover();
                removerWechat();
                dispatch.update(null);
                Cookies.remove(storages.XSRF);
                setModalType(false);
                location.reload();
              },
            },
          ],
        ]}
        title=""
        content="确认退出登录吗"
      />
    </>
  );
}
