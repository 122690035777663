import React, { useEffect } from 'react';

import './index.css';
import App from './App';
import ModalProvider from './contexts/useModal';
import UserProvider from './contexts/useUser';
import reportWebVitals from './reportWebVitals';
import { ReactDOMClient } from 'utils/js2ts';
import { request } from 'api';
import storages from 'storages';
import { AliveScope } from 'react-activation';
function ContextProviders({ children }: { children?: React.ReactNode }) {
  useEffect(() => {
    sessionStorage.removeItem(storages.homeActiveTab);

    const token = JSON.parse(localStorage.getItem(storages.accountInfo) || '{}').access_token;
    if (token) {
      request.user.GET_XSRF({ method: 'GET' });
    }
  }, []);
  return (
    // <ConfigProvider locale={ANTD_LOCAL[language]} autoInsertSpaceInButton={false}>
    <UserProvider>
      <ModalProvider>{children}</ModalProvider>
    </UserProvider>
    // </ConfigProvider>
  );
}

ReactDOMClient.createRoot(document.getElementById('root')).render(
  <ContextProviders>
    <AliveScope>
      <App />
    </AliveScope>
  </ContextProviders>,
  // <React.StrictMode>
  //   <ContextProviders>
  //     <App />
  //   </ContextProviders>
  // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
