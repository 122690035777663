import { useCopyToClipboard } from 'react-use';
import { useEffect, useState } from 'react';
import { Copy as CopyIcon } from 'assets/images';
import { Toast } from 'antd-mobile';

const Copy: React.FC<{ toCopy: string; children?: React.ReactNode }> = ({
  toCopy,
  children,
}: {
  toCopy: string;
  children?: React.ReactNode;
}) => {
  const [, setCopied] = useCopyToClipboard();
  const [isCopied, setIsCopied] = useState<boolean>(false);
  useEffect(() => {
    if (isCopied) {
      Toast.show({ content: '已复制到剪贴板' });
      const hide = setTimeout(() => {
        setIsCopied(false);
      }, 1000);
      return () => {
        clearTimeout(hide);
      };
    } else {
      return;
    }
  }, [isCopied, setIsCopied]);
  return (
    <span
      onClick={() => {
        setCopied(toCopy);
        setIsCopied(true);
      }}>
      {isCopied ? (
        // <Tooltip placement="top" title={'已复制'} defaultVisible>
        <CopyIcon />
      ) : (
        // </Tooltip>
        <>
          {/* <Tooltip placement="top" title={'复制'}> */}
          <CopyIcon />
          &nbsp;
          {children}
          {/* </Tooltip> */}
        </>
      )}
    </span>
  );
};

export default Copy;
