import Download from 'pages/OthersPage/Download';
import { lazy } from 'react';
import { useRoutes } from 'react-router-dom';
// import User from '../pages/User';
import Main from '../pages/Main/Main';
import PersonalMuseum from '../pages/PersonalMuseum';
import User from '../pages/User';
// import MyCollection from 'pages/MyCollection';
// const Main = lazy(() => import('../pages/Main/Main'));
const Login = lazy(() => import('../pages/Login/Login'));
// const PersonalMuseum = lazy(() => import('../pages/PersonalMuseum'));
const Detail = lazy(() => import('../pages/Detail'));
const MyCollection = lazy(() => import('../pages/MyCollection'));
const Series = lazy(() => import('../pages/Series'));
// const User = lazy(() => import('../pages/User'));
const UsersMuseum = lazy(() => import('../pages/OthersPage/Users/Users'));
const Organization = lazy(() => import('../pages/OthersPage/Organization/Organization'));
const FullScreen = lazy(() => import('../pages/OthersPage/FullScreen/FullScreen'));
const WeChat = lazy(() => import('../pages/WeChat/WeChat'));
const Auth = lazy(() => import('../pages/Auth/Auth'));
const Code = lazy(() => import('../pages/Code/Code'));
const BlindBox = lazy(() => import('../pages/OthersPage/BlindBox/BlindBox'));
const SearchHash = lazy(() => import('../pages/SearchHash/SearchHash'));
import KeepAlive from 'react-activation';
export const PageRouter = () =>
  useRoutes([
    {
      path: '/',
      element: (
        <KeepAlive cacheKey="Main">
          <Main />
        </KeepAlive>
      ),
    },
    {
      path: '/login',
      element: <Login />,
    },
    {
      path: '/personal-museum',
      element: <PersonalMuseum />,
    },
    {
      path: '/series',
      element: <Series />,
    },
    {
      path: '/user/*',
      element: <User />,
    },
    {
      path: '/detail',
      element: <Detail />,
    },
    {
      path: '/my-collection',
      element: <MyCollection />,
    },
    {
      path: '/users-museum',
      element: <UsersMuseum />,
    },
    {
      path: '/organization',
      element: <Organization />,
    },
    {
      path: '/full-screen',
      element: <FullScreen />,
    },
    {
      path: '/wechat-tip',
      element: <WeChat />,
    },
    {
      path: '/auth',
      element: <Auth />,
    },
    {
      path: '/code',
      element: <Code />,
    },
    {
      path: '/blind-box',
      element: <BlindBox />,
    },
    {
      path: '/download',
      element: <Download />,
    },
    {
      path: '/search-hash',
      element: <SearchHash />,
    },
    // {
    //   path: '/search-hash/:hash',
    //   element: <SearchHash />,
    // },
  ]);
