import { InfiniteScroll, PullToRefresh, Tabs, Toast } from 'antd-mobile';
import HomeCard from 'components/HomeCard/HomeCard';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';
import 'swiper/css';
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import './style.less';
import { request } from 'api';
import { useLocalStorage, useScroll } from 'react-use';
import storages from 'storages';
import { DacActive, SeriesActive, BlindBoxActive, banner, banner1, banner3 } from 'assets/images';
import LoginTip from 'components/LoginTip/LoginTip';
import clsx from 'clsx';
import Header from 'components/Header/Header';
import { ProductType } from 'types/enum';

SwiperCore.use([Autoplay]);
const colors = ['#ace0ff', '#bcffbd', '#e4fabd', '#ffcfac'];
const banners = [banner1, banner, banner3];
const items = banners.map((item, index) => (
  <SwiperSlide key={index}>
    <img src={item} />
  </SwiperSlide>
));
enum FirstPageProductEnum {
  'nft',
  'series',
  'blindBox',
}
type ActiveKeyT = 'nft' | 'series' | 'blindBox';
export default function Main() {
  const LOAD_NUMBER = 5;
  const START_COUNT = 0;
  const scrollRef = useRef(null);
  const { x, y } = useScroll(scrollRef);
  const [homeScrollTop, setHomeScrollTop] = useLocalStorage(storages.homeScrollTop);
  const [list, setList] = useState([]);
  const clientHeight = document.querySelector('body')?.clientHeight;
  const [isMore, setHasMore] = useState<boolean>(true);
  const [activeKey, setActiveKey] = useState<string>(sessionStorage.getItem(storages.homeActiveTab) || 'nft');
  const [navTop, setNavTop] = useState<boolean>(false);
  const [swiperDom, setSwiperDom] = useState<SwiperCore>();
  const [dataList, setDataList] = useState<
    { LOAD_NUMBER: number; START_COUNT: number; list: any[]; isMore: boolean }[]
  >([]);

  const scrollTop = useCallback(() => {
    const sTop = document.querySelector('.main-content')?.getBoundingClientRect().top || 0;
    if (sTop <= 0 && !navTop) {
      setNavTop(true);
    }
    if (sTop > 0 && navTop) {
      setNavTop(false);
    }
  }, [navTop]);
  // useEffect(() => {
  //   if (!y) return;
  //   sessionStorage.setItem(storages.homeScrollTop, y + '');
  // }, [y]);
  // useLayoutEffect(() => {
  //   if (Number(sessionStorage.getItem(storages.homeScrollTop)) > 150 && list.length > 0) {
  //     setNavTop(true);
  //     (scrollRef.current as any).scrollTo({
  //       top: Number(sessionStorage.getItem(storages.homeScrollTop)),
  //     });
  //   }
  // }, [list]);
  useEffect(() => {
    swiperDom?.autoplay?.start();
  });

  useEffect(() => {
    const header = document.querySelector('.home-page') as HTMLElement;
    header.addEventListener('scroll', scrollTop);
    return () => {
      header.removeEventListener('scroll', scrollTop);
    };
  }, [scrollTop]);

  useEffect(() => {
    return () => {
      setList([]);
    };
  }, []);
  const loadMore = async () => {
    let _list = dataList[FirstPageProductEnum[activeKey as ActiveKeyT]]?.list || [];
    const SkipCount = _list ? _list.length : START_COUNT;
    const MaxResultCount = LOAD_NUMBER;
    const newList = await fetchData(SkipCount, MaxResultCount);
    _list = [..._list, ...newList];
    dataList[FirstPageProductEnum[activeKey as ActiveKeyT]] = {
      ...dataList[FirstPageProductEnum[activeKey as ActiveKeyT]],
      list: _list,
      isMore: newList.length === LOAD_NUMBER,
    };
    setDataList([...dataList]);
  };
  const fetchData = async (skipCount: number, maxResultCount: number) => {
    const res = await request.nftInfo.FIRST_PAGE({
      method: 'GET',
      params: {
        skipCount,
        maxResultCount,
        firstPageProductEnum: FirstPageProductEnum[activeKey as ActiveKeyT],
      },
    });
    const newList = res.items || [];
    return newList;
  };
  const tabContent = () => {
    return (
      <PullToRefresh
        onRefresh={async () => {
          const newList = await fetchData(START_COUNT, LOAD_NUMBER);
          dataList[FirstPageProductEnum[activeKey as ActiveKeyT]] = {
            ...dataList[FirstPageProductEnum[activeKey as ActiveKeyT]],
            list: newList,
            isMore: newList.length === LOAD_NUMBER,
          };
          setDataList([...dataList]);
        }}>
        <div className="card-list flex-center flex-col">
          {dataList[FirstPageProductEnum[activeKey as ActiveKeyT]]?.list?.map((item: any, index: number) => {
            return <HomeCard width={'24.64rem'} key={index} {...item} value={item} />;
          })}
        </div>
        <InfiniteScroll
          loadMore={loadMore}
          hasMore={
            dataList[FirstPageProductEnum[activeKey as ActiveKeyT]]?.isMore === undefined
              ? true
              : dataList[FirstPageProductEnum[activeKey as ActiveKeyT]]?.isMore
          }
          threshold={clientHeight}
        />
      </PullToRefresh>
    );
  };
  return (
    <>
      <Header header={true} />
      <div className="home-page" ref={scrollRef}>
        <div className="banner">
          <Swiper className="swiper-container" loop autoplay onSwiper={(swiper) => setSwiperDom(swiper)}>
            {items}
          </Swiper>
        </div>
        <div className="main-content">
          <Tabs
            className={clsx(navTop && 'nav-top')}
            activeKey={activeKey}
            onChange={(key) => {
              setActiveKey(key);
              sessionStorage.setItem(storages.homeActiveTab, key);
            }}>
            <Tabs.Tab
              title={activeKey === 'nft' ? <img className="active-img" src={DacActive} /> : '数字藏品'}
              key="nft">
              {tabContent()}
            </Tabs.Tab>
            <Tabs.Tab
              title={activeKey === 'series' ? <img className="active-img" src={SeriesActive} /> : '藏品系列'}
              key="series">
              {tabContent()}
            </Tabs.Tab>
            <Tabs.Tab
              title={activeKey === 'blindBox' ? <img className="active-img-blindbox" src={BlindBoxActive} /> : '盲盒'}
              key="blindBox">
              {tabContent()}
            </Tabs.Tab>
          </Tabs>
        </div>
        <LoginTip></LoginTip>
      </div>
    </>
  );
}
