import { useCallback, useEffect, useState } from 'react';
import { useToggle } from 'react-use';
// import Player from 'xgplayer';
import './style.less';
export default function Video({
  url,
  src,
  autoplay = true,
  onEnded,
}: {
  url: string;
  src?: string;
  autoplay?: boolean;
  onEnded?: () => void;
}) {
  const [player, setPlayer] = useState<any>();
  const [isFullScreen, setFullScreen] = useToggle(true);
  useEffect(() => {
    const player = new (window as any).Player({
      id: 'mse',
      url: url,
      // download: true,
      playsinline: true,
      autoplay: autoplay,
      closeVideoTouch: true,
      lang: 'zh-cn',
      videoInit: true,
      fluid: true,
      // controls: false,
    });
    setPlayer(player);
    player.getFullscreen(player.root);
    player.once('ended', function () {
      console.log('end');
      onEnded?.();
    });
  }, []);
  const mseClick = () => {
    if (isFullScreen) {
      player?.exitFullscreen?.(player?.root);
    } else {
      player?.getFullscreen?.(player?.root);
    }
    setFullScreen(!isFullScreen);
  };
  return <div id="mse" onClick={mseClick}></div>;
}
