import CommonModal from 'components/Modal/CommonModal/CommonModal';
import { ChangeName, ChangeTelNumber, HarvestAddress, Verified } from './ModalContent';
import { useCallback, useMemo, useState } from 'react';
import './UserInfo.less';
import './CityCascader.less';
import { useUser } from 'contexts/useUser';
import { UserItemKey, UserListValue, AfterActionParam, UserInfoPopupProps } from 'pages/User/types';
import BaseTitle from 'components/BaseTitle';
import { Certified, NoCertified } from 'assets/images';
import { useLocalStorage } from 'react-use';
import storages from 'storages';
import { Toast } from 'antd-mobile';
import { WeChatPersonType } from 'contexts/useUser/hooks';
import { useModalDispatch } from 'contexts/useModal/hooks';

export default function UserInfo({ onClose }: UserInfoPopupProps) {
  const { setPhoneModal } = useModalDispatch();
  const [modalKey, setModalKey] = useState<UserItemKey | boolean>();
  const [{ nickName, phoneNumber, consigneeAreaId, consigneeAddress, isVerifiedName }] = useUser();
  const [weChatPerson, setWeChatPerson] = useLocalStorage<WeChatPersonType>(storages.weChatPerson);
  const userInfoList: { [x in UserItemKey]: UserListValue } = useMemo(
    () => ({
      nickName: {
        key: 'nickName',
        title: '昵称',
        modalTitle: '修改昵称',
        value: '未登录',
      },
      isVerified: {
        key: 'isVerified',
        title: '实名认证',
        modalTitle: '实名认证',
        value: '未认证',
      },
      phoneNumber: {
        key: 'phoneNumber',
        title: '手机号',
        modalTitle: '修改手机号',
        value: '未填写',
      },
      shippingAddress: {
        key: 'shippingAddress',
        title: '收货信息',
        modalTitle: '收货地址',
        value: '未填写',
      },
    }),
    [],
  );
  const valueMap = useMemo(
    () => ({
      nickName: nickName ?? weChatPerson?.nickname ?? '未设置',
      isVerified: isVerifiedName ? (
        <span>
          <img src={Certified} className="certified-img"></img>已认证
        </span>
      ) : (
        <span>
          <img src={NoCertified} className="certified-img"></img>未认证
        </span>
      ),
      phoneNumber: phoneNumber ?? '',
      shippingAddress: consigneeAddress ?? '未设置',
    }),
    [nickName, weChatPerson?.nickname, isVerifiedName, phoneNumber, consigneeAddress],
  );

  const afterAction = useCallback((props?: AfterActionParam) => {
    if (props?.type === 'cancel' || props?.type === 'success') return setModalKey(false);
    // if (props?.type === 'fail') return setToastProps(props);
    // if (props?.type === 'success') {
    //   return setToastProps(props);
    // }
  }, []);

  const ModalContent = useMemo(
    () => ({
      nickName: <ChangeName afterAction={afterAction} />,
      isVerified: <Verified afterAction={afterAction} />,
      phoneNumber: <ChangeTelNumber afterAction={afterAction} />,
      shippingAddress: <HarvestAddress afterAction={afterAction} />,
    }),
    [afterAction],
  );
  const itemClick = (item: any) => {
    if (!phoneNumber) return setPhoneModal(true);
    if (item.key === 'phoneNumber' && !isVerifiedName) {
      return Toast.show({
        content: '请先进行实名认证，再修改手机号',
      });
    }
    if (item.key === 'isVerified' && isVerifiedName) {
      return;
    }
    return setModalKey(item.key);
  };
  return (
    <div className="content-bg-color user-info-wrapper">
      <BaseTitle title="个人资料" goBack={onClose} />
      <ul className="user-info-content">
        {Object.values(userInfoList).map((item) => (
          <li
            className="flex-between-center"
            key={item.key}
            onClick={() => {
              itemClick(item);
            }}>
            <span className="title">{item.title}</span>
            <span className="value overflow-ellipsis">{valueMap[item.key] ?? '未登录'}</span>
          </li>
        ))}
      </ul>

      <CommonModal
        className="user-info-setting-modal"
        title={typeof modalKey === 'string' ? userInfoList?.[modalKey]?.modalTitle : ''}
        visible={!!modalKey}>
        {(typeof modalKey === 'string' && ModalContent?.[modalKey]) ?? ''}
        {/* <ToastInModal container={modalRef?.current} {...toastProps} /> */}
      </CommonModal>
    </div>
  );
}
