import { useMemo } from 'react';
import { useModal } from '.';
import { basicModalView, modalViewTypes } from './actions';

export function useModalDispatch() {
  const [, { dispatch }] = useModal();
  const modalDispatch = useMemo(() => {
    return Object.fromEntries(
      Object.keys(basicModalView).map((key) => [
        key,
        (action: boolean) => dispatch(basicModalView[key as modalViewTypes].actions(action)),
      ]),
    );
  }, [dispatch]);
  return modalDispatch as unknown as { [k in modalViewTypes]: Function };
}
