import './App.less';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import { PageRouter } from 'routes';
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import Modals from 'components/Modal';
import { Suspense, useCallback, useMemo, useState } from 'react';
import wx from 'weixin-js-sdk-ts';
import { useDebounce, useEffectOnce, useLocalStorage, useLocation, useSearchParam } from 'react-use';
import { useEffect } from 'react';
import storages from 'storages';
import wechatInfo from 'constants/wechatInfo';
import {
  useGetToken,
  useMyProfile,
  useVerifyIsBindPhoneNumber,
  useWeChatLogin,
  WeChatPersonType,
} from 'contexts/useUser/hooks';
import { useUser } from 'contexts/useUser';
import { useModalDispatch } from 'contexts/useModal/hooks';
// import VConsole from 'vconsole';
const appEnv = process.env.REACT_APP_ENV;
function App() {
  const [accountInfo] = useLocalStorage(storages.accountInfo);
  const [weChatPerson] = useLocalStorage<WeChatPersonType>(storages.weChatPerson);
  const weChatLogin = useWeChatLogin();
  const getVerifyIsBindPhoneNumber = useVerifyIsBindPhoneNumber();
  const getToken = useGetToken();
  const getProfile = useMyProfile();
  const modalDispatch = useModalDispatch();
  const { href, pathname } = useLocation();
  const [{ id, openid, phoneNumber }] = useUser();
  const wxCode = useSearchParam('code');
  const wxState = useSearchParam('state');
  const ua = navigator.userAgent;
  const isWechat = ua.includes('MicroMessenger');

  useEffect(() => {
    // new VConsole();
    const token = JSON.parse(localStorage.getItem(storages.accountInfo) || '{}').access_token;
    if (!token && isWechat) {
      getToken();
    }
  }, []);
  const weChatLoginAndInfo = useCallback(async (wxCode: string, wxState: string) => {
    await weChatLogin({ wxCode, wxState });
  }, []);

  useEffect(() => {
    if (!isWechat && (pathname === '/download' || pathname === '/search-hash')) return;
    if (wxCode && wxState && !accountInfo) {
      weChatLoginAndInfo(wxCode, wxState);
      modalDispatch.setLoginModal(false);
    }
    // else if (!accountInfo && !weChatPerson?.openid && appEnv !== 'dev') {
    //   modalDispatch.setLoginModal(true);
    // } else if (!accountInfo && weChatPerson?.openid && appEnv !== 'dev') {
    //   getVerifyIsBindPhoneNumber(weChatPerson?.openid).then((res) => {
    //     if (res) {
    //       getToken(weChatPerson?.openid);
    //     } else {
    //       modalDispatch.setPhoneModal(true);
    //     }
    //   });
    // }
  }, [accountInfo]);

  useEffect(() => {
    if (accountInfo && id && !phoneNumber) {
      // modalDispatch.setPhoneModal(true);
    }
  }, [(accountInfo as any)?.access_token, phoneNumber]);

  useDebounce(
    () => {
      if (!id && accountInfo) {
        getProfile();
      }
    },
    1000,
    [id, accountInfo],
  );

  useEffect(() => {
    const wechat = ua.includes('MicroMessenger');
    const isMobile = /Android|iPhone|iPad/.test(ua);
    // if (appEnv !== 'dev' && !(wechat && isMobile) && pathname !== '/download' && pathname !== '/search-hash') {
    //   pathname !== '/wechat-tip' ? (location.href = '/wechat-tip') : '';
    // } else {
    //   pathname === '/wechat-tip' ? (location.href = '/') : '';
    // }
    wx.config({
      debug: false,
      timestamp: new Date().getTime(),
      nonceStr: 'Wm3WZYTPz0wzccnN',
      signature: '55eba98f4226fcedb349e5a70d67013e33d5a239',
      jsApiList: ['hideMenuItems', 'hideAllNonBaseMenuItem', 'showMenuItems', 'updateAppMessageShareData'], // 必填，需要使用的JS接口列表
      openTagList: [],
      ...wechatInfo,
    });
    wx.ready(() => {
      // console.log('wx success');
      wx.hideAllNonBaseMenuItem();
      wx.showMenuItems({ menuList: ['menuItem:share:appMessage', 'menuItem:share:timeline', 'menuItem:favorite'] });
      wx.updateAppMessageShareData({
        title: '111',
        desc: '2222',
        link: 'https://jbl.intohash.com',
        imgUrl: '',
        success: function () {
          // 设置成功
          console.log('设置成功');
        },
        cancel: function () {
          // 取消
        },
      });
    });
    wx.error((e: any) => {
      // console.log('wx error', e);
      // alert(JSON.stringify(e));
    });
  }, [href, pathname, ua]);

  return (
    <Suspense fallback={null}>
      <div className="app-container">
        <BrowserRouter>
          <Modals />
          <Header />
          <PageRouter />
          <Footer />
        </BrowserRouter>
      </div>
    </Suspense>
  );
}

export default App;
