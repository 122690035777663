import { Lock } from 'assets/images';
import { loginRedirect } from 'components/Modal/LoginModal/LoginModal';

import './style.less';
export default function UnLoggedIn() {
  return (
    <div className="un-logged-in flex-1 flex-center flex-col">
      <Lock />
      <p onClick={() => console.log('login')} className="flex">
        请&nbsp;
        <span className="login-btn" onClick={loginRedirect}>
          登录
        </span>
        &nbsp;后查看
      </p>
    </div>
  );
}
