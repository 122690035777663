import { request } from 'api';
import clsx from 'clsx';
import CardList from 'components/CardList/CardList';
import UnLoggedIn from 'pages/PersonalMuseum/components/NotloggedIn';
import { useState } from 'react';

export default function Received({ className, ...options }: { className?: string }) {
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const url = request.user.TRANSFER_RECORD;
  const params = { transferFilter: 1 };
  return (
    <div {...options} className={clsx('my-pavilion-subpage flex-col', className)}>
      {isLoggedIn ? <CardList url={url} isPersonalMuseum={true} params={params} /> : <UnLoggedIn />}
    </div>
  );
}
