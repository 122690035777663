import { Button, Cascader } from 'antd-mobile';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import options from './cities.json';
export default function CityCascader({
  onChange,
  value,
  isError,
  ...props
}: any & {
  value?: string;
  onChange?: any;
  isError?: boolean;
}) {
  const [visible, setVisible] = useState<boolean>();
  const cascaderRef = useRef<any>();
  console.log(visible, 'visible===');
  useEffect(() => {
    if (!cascaderRef?.current) return;
    console.log(cascaderRef?.current, 'CityCascader===');
  }, []);

  return (
    <>
      <Button
        className={clsx('city-select-wrapper', !!isError && 'input-status-error input-transform')}
        onClick={() => setVisible(true)}></Button>
      <Cascader
        ref={cascaderRef}
        {...(props as any)}
        visible={visible}
        value={value?.map((i: string) => i)}
        options={options}
        onConfirm={onChange}
        onClose={() => {
          setVisible(false);
        }}>
        {(items) => {
          if (items.every((item) => item === null)) {
            return (
              <div className="city-select-text" onClick={() => setVisible(true)}>
                未选择
              </div>
            );
          } else {
            return (
              <div className="city-select-text" onClick={() => setVisible(true)}>
                {items.map((item) => item?.label ?? '未选择').join('-')}
              </div>
            );
          }
        }}
      </Cascader>
    </>
  );
}
