export function shortenStringStar(address?: string | null, chars = 10, stars = 4): string {
  const parsed = address;
  if (!parsed) {
    return '';
  }
  if (stars <= 0) {
    stars = 4;
  }
  let i = 0;
  let starString = '*';
  while (i < stars) {
    starString += '*';
    i++;
  }
  return `${parsed.substring(0, chars)}${starString}${parsed.substring(parsed.length - chars - 1)}`;
}

export function isWechat() {
  const ua = navigator.userAgent;
  return ua.includes('MicroMessenger');
}
