import { ReactNode } from 'react';

export const serviceProtocol = (): ReactNode => (
  <>
    <h3>海河数藏平台用户使用规则</h3>
    <br></br>
    <h4>一、不得利用平台从事违法违规或不当行为</h4>
    <p>
      为了营造积极、健康的互联网生态环境，用户在平台发布的内容应当符合当代社会主流价值观。如您在使用平台内的服务时，涉嫌存在违法违规等行为，我们有权基于风险控制需要，停止向您提供相应服务。根据《中华人民共和国网络安全法》《互联网信息服务管理办法》、《网络信息内容生态治理规定》等法律法规，您不得利用平台提供的服务从事违法违规行为，包括但不限于:
    </p>
    <h4>(一)不当行为</h4>
    <p>
      1.涉嫌侵扰用户安宁、造成用户使用体验减损或者影响用户正常使用的骚扰行为(如以评论、私信、求关注等方式向他人反复发送重复、类似、诉求相同的信息，对其他用户造成干扰的);
    </p>
    <p>
      2.用诅咒、夸张或营销、诱导等性质言语来胁
      迫、引诱或煽动用户分享、关注、点赞、评论、发布广告或将用户引导至其他平台或个人;
    </p>
    <p>3.设置的昵称、头像、签名或简介等违反法律法规或违背社会公德、公序良俗;</p>
    <p>4.发布含有人身攻击或其他具有欺骗、诱导从事危险行为或不文明行为的内容;</p>
    <p>
      5.涉嫌通过恶意注册账号、刷单、炒作、虚假交易或评论、虚拟币交易、场外交易、使用外挂等形式，进行数据造假或实施其他作弊行为(如虚假的粉丝关注、点赞、评论等);
    </p>
    <p>6.其他违反法律法规、国家政策以及违背社会公 共利益、公共道德或妨害平台正常运行、侵犯他人合法权益的行为。</p>
    <h4>(二)制作、复制、发布含有以下违法违规内容的信</h4>
    <p>1.反对宪法所确定的基本原则的</p>
    <p>2.危害国家安全，泄露国家秘密，颠覆国家政 权，破坏国家统一的</p>
    <p>3.损害国家荣誉和利益的，如损害国旗国徽形象;</p>
    <p>4.歪曲、丑化、亵渎、否定英雄烈士事迹和精 神，以侮辱、诽谤或者其他方式侵害英雄烈士的姓名、肖像、名誉、荣誉的</p>
    <p>5.宣扬恐怖主义、极端主义或者煸动实旅恐怖活 动、极端主义活动的</p>
    <p>6.散布淫秽、色情、赌博、暴力、凶杀、恐怖或 者教唆犯罪的;</p>
    <p>7.煽动民族仇恨、民族歧视，破坏民族团结的;</p>
    <p>8.破坏国家宗教政策，宣扬邪教和封建迷信的;</p>
    <p>9.散布谣言，扰乱经济秩序和社会秩序的;</p>
    <p>10.侮辱或者诽谤他人，侵害他人合法权益的;</p>
    <p>11.攻击诋毁党和国家领导人，捏造领导人负面信息，滥用领导人形象的;</p>
    <p>
      12.涉及违禁物品(如枪支、毒品、弹药、虚拟货币等)、违禁活动(如传销、洗钱、售假、黑社会等)或欺诈，赌博等违法行为的;
    </p>
    <p>
      13.其他违反法律法规、社会主义制度、国家利益、公民合法权益、公共秩序、社会道德风尚和信息真实性等七条底线要求的。
    </p>
    <h4>(三)制作、复制、发布含有以下不良内容的信息</h4>
    <p>
      1.涉及未成年人隐私或危害未成年人安全和健康的相关内容，包括但不限干引发未成年人模仿不安全行为和讳反社会公德行为、诱导未成年人不良嗜好等
    </p>
    <p>2.散布淫秽、色情内容或者涉及色情擦边、性暗示类信息的</p>
    <p>3.宣扬暴力、恶意谩骂，包括但不限于展现血腥、惊悚、残忍等致人身心不适的;</p>
    <p>4.危害系统和网络安全的，包括含有钓鱼网站、病毒、文件、计算机代码或程序等信息</p>
    <p>5.炒作绯闻、丑闻、劣迹等或宣扬低俗、庸俗、媚俗内容的</p>
    <p>6.涉及中奖类、虚假夸大、假冒伪劣等不实或可疑内容的;</p>
    <p>7.不当评述自然灾害、重大事故等灾难，或者煽动人群歧视、地域歧视等的;或者其他对网络生态造成不良影响的内容;</p>
    <h4>(四)制作复制、发布含有以下侵权内容的信息</h4>
    <p>1.擅自使用他人已经登记注册的企业名称或商 标，侵犯他人企业名称权及商标专用权</p>
    <p>2.擅自使用他人姓名、肖像，侵害他人姓名权、肖像权等合法权益</p>
    <p>3.冒用身份，使用他人特征性信息，足以误导公众的;</p>
    <p>4.未经授权发布他人原创文章或作品，侵犯他人知识产权等合法权益的</p>
    <p>5.未经授权发布他人身份证号码、联系方式、家 庭住址、照片等个人隐私资料，侵犯他人肖像权、隐私权等合法权益的;</p>
    <p>6.侮辱或者诽谤他人，侵害他人名誉等合法权益的;</p>
    <p>7.未经授权发布企业商业秘密，侵犯企业合法权益的;</p>
    <p>7.其他侵犯他人合法权益的行为。</p>

    <h4>二、 违规行为处罚 </h4>
    <p>
      我们将全力保障您的权益，并不断努力为您提供更好的服务。为了保障您与他人的合法权益，在下列情况下平台将根据您的行为风险程度采取必要措施，包括但不限于(i)警告，(ii)删除您发布的相关信息，(iii)禁言，(iv)临时或永久地暂停或关闭您账户的部分功能，例如关闭评论/回复功能、禁止修改个人资料、禁止创建新展馆主题、禁止关注/点赞、禁止被推荐等，(v)封禁账号，和/或(vi)停止向您提供部分或全部服务:
    </p>
    <p>1.您违反本规则约定的内容</p>
    <p>2.您违反您与我们签署的《海河数藏平台用户服务协议》、以及平台其他规则的任何条款</p>
    <p>
      3.基于法律法规及政策的规定、监管机构要求或风险管控的需要;或者其他您存在涉嫌违反国家法律法规的行为或从事侵害他人合法权益的行为。
    </p>
    <p></p>
    <p>
      当我们发现或收到第三方举报或投诉后认定您存在上述情形的，我们将采取相应的处置措施。但对您采取的上述处置措施并不排除我们有权依据《平台用户服务协议》以及平台其他规则对您同时实施其他类型的处罚。如果您对我们实施的处罚措施有异议的，您可以联系客服提交相关证明材料进行电诉，我们将依据事实进行处理。
    </p>
    <p></p>
    <h4>三、 创作者账号认证 </h4>
    <p>
      为了向平台内数字藏品的创作者更好地提供服务，提高创作者账号信息的真实、合法、有效性，我们特此对创作者账号提供以下官方认证服务。
    </p>
    <p>
      1.官方认证分为个人认证和机构认证。在您作为创作者的情况下，可以以个人身份申请个人认证，或经所在企业授权以机构身份申请机构认证。目前认证务仅向平台邀请的符合资格的特定创作者开放。
    </p>
    <p>2.完成官方认证后，您将享有认证用户的相关权利，包括但不限于在平台上对外展示您账号的官方认证标识。</p>
    <p>
      3.您可以自主选择是否申请认证或取消认证。您理解每个账号在同一时间只能处于一种认证状态，即未认证、个人认证或机构认证。您的账号认证状态以平台实际展示为准。您理解一旦您取消认证成功，该账号将不再展示认证相关信息且不再享有认证相关权利，因此请您谨慎操作。
    </p>
    <p>
      4.您同意如您申请官方认证，需要向我们提供认证所需的必要信息和资料，包括您的账号认证类型、认证称号以及平台提示的其他认证资料。
    </p>
    <p>
      5.您的认证行为应当符合本规则以及《海河数藏平台用户服务协议》的规定。您将对通过您的账号实施的行为和言论、或发布的信息和内容独立承担责任，无论您的账号是否已通过认证。平台官方认证服务仅限于对创作者账号运营主体提交的资料及信息进行合理、谨慎的形式审查。通过了平台官方认证并不代表我们对该账号主体的真实性、合法性和有效性等做出任何担保、承诺或保证。
    </p>
    <h4>三、 其他 </h4>
    <p>
      您理解并同意，我们可能会根据相关法律法规、监管政策或风险防范的需要对本规则不时进行修改、调整、补充和公示。如果您不同意本规则的该等更新，请勿使用平台的服务。您如果继续使用平台的服务将视为您同意并遵守更新的规则。
    </p>
    <p></p>
  </>
);
export const privacyProtocol = (): ReactNode => (
  <>
    <h3>海河数藏用户隐私协议</h3>
    <br></br>
    <h4>一、我们如何收集和使用您的个人信息</h4>
    <h4>根据相关法律法规及国家标准，我们可能在下列情形下依法处理您的个人信息:</h4>
    <p>(1)取得您的同意;</p>
    <p>(2)根据您的要求订立和履行合同所必需的;</p>
    <p>(3)履行法定义务所必需的，如反洗钱、反恐怖副资、实名管理等相关法律法规要求的必要信息</p>
    <p>(4)与国家安全、国防安全直接相关的</p>
    <p>(5)为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需</p>
    <p>(6)与犯罪侦查、起诉、审判和判决执行等直接相关的;</p>
    <p>(7)信息您自行公开的</p>
    <p>(8)从合法公开披露的信息中收集的个人信息，例如:合法的新闻报道、政府信息公开等渠道;</p>
    <p>(9)用于维护所提供的服务的安全稳定运行所必需的，例如:发现、处置产品或服务的故障;</p>
    <p>(10)法律法规规定的其他情形。</p>
    <p></p>
    <p>
      需要注意的是，当您的个人信息处理属于前述第(2)至第(10)项情形时，我们可能会依据所适用法律法规的要求而无需再征得您的同意。
    </p>
    <p>1.2帮助您成为我们的用户并提供账号服务</p>
    <p>1.2.1账号的注册、登录</p>
    <p>
      为遵守法律法规的要求，您在注册并登录海河数藏账号后才能使用评论、分享及其他用户服务，在未注册或登录账号的情况下您可以进行浏览、搜索。
    </p>
    <p>
      账号的注册:当您注册海河数藏账号时，您需要提供手机号码以创建账号。手机号码是履行国家法律法规关于网络实名制要求的必要信息，如您未提供手机号码用于注册、登录，我们可能无法为您提供评论、分享等功能。同时为完善您的账号信息，您可以提供非注册必须信息(如:头像、账号名、昵称、简介)来完善您的网络身份标识信息。当您使用编辑头像或上传图片功能时，我们在获得您的授权同意后将访问您的相册(存储)权限。
    </p>
    <p>
      授权登录:为了使您便捷的使用第三方账号登录并使用我们的产品/服务，我们会根据您的授权获取您在第三方平台的注册账号信息(头像、昵称、ID编号及您授权的其他信息)，并与您的海河数藏账号进行绑定。为了满足相关法律法规的网络实名制要求，您使用第三方平台账号首次授权登录时，我们需要获取您的手机号码并进行验证，验证成功后该手机号将与您的账号绑定。此类信息属于该功能的必要信息，若您不提供这类信息，您可能无法正常注册、登录，
    </p>
    <p>
      请您注意，为向您提供便捷的登录及账号实名认证功能，您使用支付宝账号进行授权登录时，我们将获取您的支付宝账号头像、昵称、ID编号及您的身份证号码:如您不愿向我们提供上述信息，您可以选择页面展示的其他账号注册/登录方式。
    </p>
    <p>1.2.2账号实名认证</p>
    <p>
      在您使用实名认证的功能或购买数字藏品等相关服务时，根据相关法律法规，您需要向我们和提供认证服务的第三方认证机构提供您的真实姓名、身份证号
      码、手机号码、面部特征(如需)等身份证明信息以完成验证，特别且必要情况下基于安全考虎，为了确保我们为您本人提供服务，我们可能会根据您提供的信息进行校验，将通过人脸识别的方式验证您的身份。当您使用实名认证功能时，我们在获得您的授权同意后将访问您的摄像头权限。身份证号码、电话号码、人脸信息属于您的敏感个人信息，您可以拒绝提供，此时您将可能无法获得相关服务，但不影响其他功能与服务的正常使用。
    </p>
    <p>1.2.3账号找回</p>
    <p>
      当您使用账号找回功能时，为保障您账号的安全所必需，在找回账号时我们可能需要您提供相关信息以进行验证，必要时可能通过实名验证、人脸识别等方式验证您的身份。
    </p>
    <p>1.3帮助您购买或转赠数字藏品</p>
    <p>
      1.3.1在您购买或转赠数字藏品时，根据区块链信息服务以及反洗钱相关要求，您需要完成账号实名认证。如您的账号未完成实名认证，您可能无法使用购买或转赠数字藏品的功能，但不影响您继续使用我们的其他服务。
    </p>
    <p>
      1.3.2在您购买或转赠数字藏品时，我们会通过系统为您生成您的购买或转赠记录并上链存储。基干区块链技术的特性，数字藏品具有加密和不可篡改的特点，您购买或转赠相关数字藏品后，您的购买或转赠信息(交易时间、交易订单号)将进行加密处理并记录在区块链中，无法进行算做计除。就可以通过【我的订单】查看和管理您的交易信息。
    </p>
    <p>
      1.3.3当您使用购买提醒功能或预约提醒功能时，我们将在获得您的授权同意后访问您的日历权限设置日程提醒:若您未开启日历权限，将无法使用上述功能，但不会影响您正常使用我们的其他功能。
    </p>
    <p></p>
  </>
);
