// import html2canvas from 'html2canvas';
import { QRCodeSVG } from 'qrcode.react';
import { useEffect, useMemo, useState } from 'react';
import { useLocalStorage, useLocation } from 'react-use';
import { LogoBlue, logoTextYellow, IdentifierIcon, Authentication } from 'assets/images';
import './style.less';
import { url } from 'inspector';
import storages from 'storages';
const { html2canvas } = window as any;
export const saveImg = () => {
  html2canvas(document.querySelector('#capture') as HTMLElement, { backgroundColor: null }).then((canvas: any) => {
    const dataImg = new Image();
    dataImg.src = canvas.toDataURL('image/png');
    const alink = document.createElement('a');
    alink.href = dataImg.src;
    alink.download = 'lenYan';
    alink.click();
  });
};
export default function DacShareCard() {
  const [imgUrl, setImgUrl] = useState('');
  const [initImgUrl, setInitImgUrl] = useState('');
  const { href, pathname, hash, ...other } = useLocation();
  const [shareInfo, setShareInfo, removeShareInfo] = useLocalStorage<any>(storages.shareInfo);

  const targetUrl = useMemo(() => {
    switch (pathname) {
      case '/personal-museum':
        return hash === '#ranking' ? href : origin + '/users-museum';
      case '/user':
        return origin;
      default:
        return href;
    }
  }, [hash, href, pathname]);

  useEffect(() => {
    html2canvas(document.querySelector('.nft-share-card') as HTMLElement, {
      backgroundColor: null,
      scale: 2,
      allowTaint: true,
      useCORS: true,
    }).then((canvas: any) => {
      const url = canvas.toDataURL('image/png');
      setImgUrl(url);
    });
  }, [targetUrl]);
  useEffect(() => {
    setImgUrl('');
  }, [href]);
  useEffect(() => {
    // console.log(shareInfo.image);
    // console.log('initImgUrl', initImgUrl);
    // console.log(!initImgUrl);
    // if (shareInfo.image && !initImgUrl) {
    //   convertImgToBase64(shareInfo.image);
    // }
    // getImgBase64();
    // getBase64(LogoBlue);
    // getBase64(shareInfo.image);
  }, [shareInfo.image, initImgUrl]);
  function getImgBase64() {
    let base64 = '';
    console.log('base64', base64);

    const img = new Image();
    console.log('img', img);
    img.crossOrigin = '';
    img.src = 'https://sinodac.oss-cn-beijing.aliyuncs.com/host/3ad1eab4-7a95-40f6-8956-0ae27d518d75｜勋章图.png';
    console.log(img);
    img.onload = function () {
      base64 = image2Base64(img);
      console.log(base64);

      setInitImgUrl(base64);
    };
  }
  function image2Base64(img: any) {
    // const canvas = document.createElement('canvas');
    // const ctx = canvas.getContext('2d');
    // const img = new Image();

    // img.crossOrigin = 'anonymous';
    // console.log(img);
    // img.src = url;
    // img.onload = function () {
    //   console.log('canvas', canvas);
    //   canvas.height = img.height;
    //   canvas.width = img.width;
    //   ctx?.drawImage(img, 0, 0);
    //   console.log('ctx', ctx);
    //   const dataURL = canvas.toDataURL('image/png');
    //   setInitImgUrl(dataURL);
    //   // callback.call(this, dataURL);
    //   // canvas = null;
    // };
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext('2d');
    ctx?.drawImage(img, 0, 0, img.width, img.height);
    const dataURL = canvas.toDataURL('image/png');
    return dataURL;
  }
  function getBase64(imgUrl: any) {
    window.URL = window.URL || window.webkitURL;
    const xhr = new XMLHttpRequest();
    xhr.withCredentials = false;
    xhr.open('get', imgUrl, true);
    // 至关重要
    xhr.responseType = 'blob';
    console.log(xhr);

    xhr.onload = function () {
      console.log('this.status', this.status);

      if (this.status == 200) {
        //得到一个blob对象
        const blob = this.response;
        console.log('blob', blob);
        // 至关重要
        const oFileReader = new FileReader();
        oFileReader.onloadend = function (e) {
          // 此处拿到的已经是 base64的图片了
          const base64: any = e.target?.result;
          // console.log('方式一》》》》》》》》》', base64);
          setInitImgUrl(base64);
        };
        oFileReader.readAsDataURL(blob);
      }
    };
    xhr.send();
    xhr.onerror = function () {
      // 仅在根本无法发出请求时触发
      console.log(`Network Error`);
    };
    xhr.onprogress = function (event) {
      // 定期触发
      // event.loaded —— 已经下载了多少字节
      // event.lengthComputable = true，当服务器发送了 Content-Length header 时
      // event.total —— 总字节数（如果 lengthComputable 为 true）
      console.log(`Received ${event.loaded} of ${event.total}`);
    };
    xhr.onreadystatechange = function () {
      console.log('xhr.readyState', xhr.readyState);
    };
    // xhr.getAllResponseHeaders();
    const headers = xhr
      .getAllResponseHeaders()
      .split('\r\n')
      .reduce((result: any, current) => {
        const [name, value] = current.split(': ');
        result[name] = value;
        console.log('result', result);

        return result;
      }, {});
    console.log('headers', headers);
  }
  return (
    <>
      {!imgUrl ? (
        <div className="nft-share-card">
          <div className="nft-share-img">
            <img src={shareInfo.image + '?' + new Date().getTime()} className="img" crossOrigin="anonymous" />
          </div>
          <div className="share-info">
            <div className="nft-name">{shareInfo.nftName}</div>
            {!shareInfo.nftId ? (
              <div>
                <span>
                  <span className="limit">限量</span>
                  <span className="number">{shareInfo.limitNumber}份</span>
                </span>
              </div>
            ) : (
              <div className="line"></div>
            )}
            {!shareInfo.nftId ? (
              <div className="flex-between bottom">
                <div className="desc">
                  <div>
                    <span className="logo">
                      <img src={logoTextYellow} />
                    </span>
                    {/* 海河数藏 */}
                  </div>
                  <p className="text">扫码进入海河数藏平台</p>
                  <p className="text">更多精彩，与你一起</p>
                </div>
                <div className="qr-code">
                  <QRCodeSVG
                    value={targetUrl as string}
                    // value={origin as string}
                    includeMargin={true}
                    size={90}
                    level="H"
                    imageSettings={{
                      src: LogoBlue,
                      width: 20,
                      height: 20,
                      excavate: true,
                    }}
                    bgColor="#fff"
                    fgColor="#000"
                  />
                </div>
              </div>
            ) : (
              <div className="flex-between bottom nft-id ">
                <div className="desc">
                  <span className="title">收藏者</span>
                  <p className="text">{shareInfo.nickName}</p>
                  <span className="title">藏品编码</span>
                  <p className="nft-id-text text">
                    <img src={IdentifierIcon}></img>
                    <span>{shareInfo?.nftId}</span>
                  </p>
                  {/* <p className="text">{shareInfo.nftId}</p> */}
                  <span className="title">认证时间</span>
                  <span className="title">{shareInfo.creatorTime}</span>
                </div>
                <div className="authentication">
                  <img src={Authentication} className="authentication-img" />
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <img src={imgUrl} />
      )}
    </>
  );
}
