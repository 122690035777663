import { InfiniteScroll, Popover, PullToRefresh, Toast } from 'antd-mobile';
import BaseTitle from 'components/BaseTitle';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import './OrderRecord.less';
import { FilterIcon } from 'assets/images';
import { sleep } from 'antd-mobile/es/utils/sleep';
import { useWindowHeight } from 'hooks/useWindowSize';
import OrderCard from '../OrderCard';
import { UserInfoPopupProps } from 'pages/User/types';
import { request } from 'api/index';
import { useWechatPay } from 'hooks/useWechatPay';
import { formatTime } from 'utils/time';

export type OrderRecordType = {
  amount: number;
  creatorName: string;
  nftFile: string;
  nftHash: string;
  nftId: string;
  nftName: string;
  nftSeriasName?: string | null;
  id: string;
  orderId: string;
  orderTime: string;
  saleBatchId: string;
  status: number;
  transactionId: string;
  type: number;
  userId: string;
};
const actions = [
  { key: 100, text: '全部' },
  { key: 0, text: '订单进行中' },
  { key: 1, text: '订单完成' },
  { key: 2, text: '订单失败' },
  { key: 3, text: '订单取消' },
];
enum statusColor {
  'going',
  'finished',
  'error',
  'cancel',
}
const list = [
  {
    label: '所属系列',
    name: 'nftSeriasName',
  },
  {
    label: '发行机构',
    name: 'creatorName',
  },
  {
    label: '藏品ID',
    name: 'nftId',
  },
  {
    label: '藏品哈希',
    name: 'nftHash',
    isCopy: true,
  },
  {
    label: '购买时间',
    name: 'orderTime',
  },
  {
    label: '支付方式',
    name: 'paymentType',
  },
  {
    label: '链上交易ID',
    name: 'transactionId',
    isCopy: true,
  },
];
export default function OrderRecord({ onClose }: UserInfoPopupProps) {
  const orderRef = useRef<any>();
  const [filter, setFilter] = useState<number>(100);
  const filterExtra = useMemo(
    () => (
      <Popover.Menu
        className="user-record-popover"
        getContainer={() => orderRef?.current}
        actions={actions.map((action) => ({
          ...action,
          icon: null,
        }))}
        onAction={(node) => {
          const { key } = node as { key: number };
          setFilter(key);
        }}
        placement="bottom-start"
        trigger="click">
        <div className="flex-align-center filter-extra-wrapper">
          <FilterIcon />
          {actions.map((item) => {
            if (item.key === filter) {
              return item.text;
            }
          })}
        </div>
      </Popover.Menu>
    ),

    [filter],
  );
  const listOption = (item: any) => {
    return item?.status === 1 ? list : list.slice(0, 3).concat(list.slice(4, 5));
  };
  return (
    <>
      <div ref={orderRef} className="content-bg-color order-record-wrapper">
        <BaseTitle title={'订单记录'} extra={filterExtra} isFixedTop goBack={onClose} />
        <OrderCard
          listOption={listOption}
          type="orderRecord"
          actions={actions}
          statusColor={statusColor}
          request={request.user.ORDER_RECORD}
          filter={filter}
        />
      </div>
    </>
  );
}
