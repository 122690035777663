// import { AboutBanner } from 'assets/images';
import BaseTitle from 'components/BaseTitle';
import ProtocolPopup from 'components/ProtocolPopup';
import { ProtocolType } from 'components/ProtocolPopup/ProtocolPopup';
import { UserInfoPopupProps } from 'pages/User/types';
import { useState } from 'react';
import { AboutUsBgc } from 'assets/images/index';
import './AboutUs.less';
export default function AboutUs({ onClose }: UserInfoPopupProps) {
  const [visible, setVisible] = useState<ProtocolType | boolean>(false);

  return (
    <div className="popup-content-page about-us-wrapper">
      <BaseTitle title="关于我们" goBack={onClose} />
      <div className="about-us-content">
        {/* <img /> */}
        <img src={AboutUsBgc} />
        <div className="introduce">
          <p>
            “海河智链”是天津引元信息科技有限公司自主研发的区块链系统推出的区块链底层基础设施，目前已经通过工信部中国电子技术标准化研究院区块链系统功能测试、性能测试两项权威认证，是第一个实现完全自主可控且实时开源的区块链系统。
          </p>
        </div>
        <p className="protocol-link">
          <span onClick={() => setVisible(ProtocolType.serviceProtocol)}>《用户服务协议》</span>
          &nbsp;
          <span onClick={() => setVisible(ProtocolType.privacyProtocol)}>《隐私权政策》</span>
        </p>
      </div>
      <ProtocolPopup Protocol={visible} onClose={() => setVisible(false)} />
    </div>
  );
}
