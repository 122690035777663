import SettingList from './component/SettingList';
import UserRecord from './component/UserRecord';
import UserTem from './component/UserTem';
import './styled.less';
export default function User() {
  return (
    <div className="content-bg-color user-asset-wrapper" style={{ paddingTop: '1.14rem' }}>
      <UserTem />
      <UserRecord />
      <SettingList />
    </div>
  );
}
