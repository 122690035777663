import { AccountDefaultCover } from 'assets/images';
import './UserNoLogin.less';
import '../UserTem/UserTem.less';
import { Button } from 'antd-mobile';
import { redirectToOauth2 } from 'components/Modal/LoginModal/LoginModal';
import { useUserDispatch } from 'contexts/useUser/hooks';
import { useUser } from 'contexts/useUser';
import { useNavigate } from 'react-router-dom';
import { isWechat } from 'utils';
const env = process.env.REACT_APP_ENV;

export default function UserNoLogin() {
  // const dispatch = useUserDispatch();
  // dispatch.update({ address: 'jkjkkjjlk' });
  const nav = useNavigate();
  return (
    <div className="user-no-login-wrapper">
      <div className="flex-between user-top-wrapper">
        <img src={AccountDefaultCover} />
        <div className="flex-1 ">
          <p className="username">未登录</p>
          <p className="tel-phone">登录后可查看你的数字藏品</p>
        </div>
      </div>
      <div className="flex-1 text-center">
        <Button
          className="absolute-center login-btn"
          onClick={() => {
            // dispatch.update({ address: 'jkjkkjjlk' });
            isWechat() ? redirectToOauth2() : nav('/login?type=login');
          }}>
          立即登录
        </Button>
      </div>
    </div>
  );
}
