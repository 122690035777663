import { useUser } from 'contexts/useUser';
import { lazy, useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import OrderRecord from './component/OrderRecord';
import TransferRecord from './component/TransferRecord';
import UserInfo from './component/UserInfo/UserInfo';
import WatchList from './component/WatchList';
import User from './User';
import UserNoLogin from './component/UserNoLogin';
import { useLocalStorage } from 'react-use';
import { WeChatPersonType } from 'contexts/useUser/hooks';
import storages from 'storages';

export const UserRouter = () => {
  const [{ id, phoneNumber, openid }] = useUser();
  const [weChatPerson, setWeChatPerson] = useLocalStorage<WeChatPersonType>(storages.weChatPerson);
  return useRoutes([
    {
      path: '/',
      element: weChatPerson?.openid || openid || phoneNumber ? <User /> : <UserNoLogin />,
    },
    {
      path: '/user-info',
      element: <UserInfo />,
    },
    {
      path: '/order-record',
      element: <OrderRecord />,
    },
    {
      path: '/watch-list',
      element: <WatchList />,
    },
    {
      path: '/transfer-record',
      element: <TransferRecord />,
    },
  ]);
};

export default function UserWrapper() {
  return <UserRouter />;
}
