import { basicActions } from 'contexts/utils';

const modalActions = {
  setLoginModal: 'SET_LOGIN_MODAL',
  setBindModal: 'SET_BIND_MODAL',
  setAuthModal: 'SET_AUTH_MODAL',
  setPhoneModal: 'SET_PHONE_MODAL',
  setShareModal: 'SET_SHARE_DRAWER',
  setVerifiedModal: 'SET_VERIFIED_MODAL',
  destroy: 'DESTROY',
};

export type modalState = {
  loginModal: boolean;
  bindModal: boolean;
  authModal: boolean;
  phoneModal: boolean;
  shareModal: boolean;
  verifiedModal: boolean;
};

export type modalViewTypes =
  | 'setLoginModal'
  | 'setBindModal'
  | 'setAuthModal'
  | 'setPhoneModal'
  | 'setShareModal'
  | 'setVerifiedModal'
  | 'destroy';

export const basicModalView: {
  [x in modalViewTypes]: { type: any; actions: (arg0: boolean) => { type: string; payload: any } };
} = {
  setLoginModal: {
    type: modalActions['setLoginModal'],
    actions: (loginModal: boolean) =>
      basicActions(modalActions['setLoginModal'], {
        loginModal,
        destroy: true,
      }),
  },
  setBindModal: {
    type: modalActions['setBindModal'],
    actions: (bindModal: boolean) =>
      basicActions(modalActions['setBindModal'], {
        bindModal,
        destroy: true,
      }),
  },
  setAuthModal: {
    type: modalActions['setAuthModal'],
    actions: (authModal: boolean) =>
      basicActions(modalActions['setAuthModal'], {
        authModal,
        destroy: true,
      }),
  },
  setPhoneModal: {
    type: modalActions['setPhoneModal'],
    actions: (phoneModal: boolean) =>
      basicActions(modalActions['setPhoneModal'], {
        phoneModal,
        destroy: true,
      }),
  },
  setShareModal: {
    type: modalActions['setShareModal'],
    actions: (shareModal: boolean) => basicActions(modalActions['setShareModal'], { shareModal }),
  },
  setVerifiedModal: {
    type: modalActions['setVerifiedModal'],
    actions: (verifiedModal: boolean) =>
      basicActions(modalActions['setVerifiedModal'], { verifiedModal, destroy: true }),
  },
  destroy: {
    type: modalActions['destroy'],
    actions: () => basicActions(modalActions['destroy']),
  },
};
