import { useEffect, useMemo, useState } from 'react';
import { Arrow, TokenIcon } from 'assets/images';
import './OrderCard.less';
import { shortenStringStar } from 'utils';
import Copy from 'components/Copy';
import { Collapse, InfiniteScroll, PullToRefresh } from 'antd-mobile';
import clsx from 'clsx';
import { useWindowHeight } from 'hooks/useWindowSize';
import { EnumType } from 'typescript';
import { formatTime } from 'utils/time';
import { useCancelWeChatPay, useWechatPay } from 'hooks/useWechatPay';
import { OrderRecordType } from './../OrderRecord/OrderRecord';
import { TransferRecordType } from './../TransferRecord/TransferRecord';
import { REACT_APP_STATIC_URL } from 'constants/index';
import { PaymentType } from 'types/enum';
const { Panel } = Collapse;

export default function OrderCard({
  type,
  listOption,
  actions,
  statusColor,
  filter,
  request,
}: {
  type?: string;
  actions?: any;
  statusColor?: any;
  listOption: (a: any) => any[];
  filter?: number;
  request: any;
  cancelOrder?: (a: string) => void;
  payOrder?: (a: string) => void;
}) {
  const weChatPay = useWechatPay();
  const cancelWeChatPay = useCancelWeChatPay();
  const [list, setList] = useState<Array<OrderRecordType | TransferRecordType>>([]);
  const clientHeight = useWindowHeight();
  const [isFirst, setIsFirst] = useState<boolean>(true);
  const [isMore, setHasMore] = useState<boolean>(true);
  const LOAD_NUMBER = 20;
  const START_COUNT = 0;
  useEffect(() => {
    if (isFirst) return;
    loadMore(true);
    return () => {
      setList([]);
    };
  }, [filter]);
  const loadMore = async (init?: boolean) => {
    const skipCount = list && !init ? list.length : START_COUNT;
    const maxResultCount = LOAD_NUMBER;
    const newList = await fetchData(skipCount, maxResultCount);
    if (init) {
      setList(newList);
    } else {
      setList((v) => v.concat(newList));
    }
    setHasMore(newList.length === LOAD_NUMBER);
    setIsFirst(false);
  };
  const fetchData = async (skipCount: number, maxResultCount: number) => {
    const _params: any = { skipCount, maxResultCount };
    if (type === 'TransferRecord') {
      _params.transferFilter = filter === 100 ? null : filter;
    } else {
      _params.paymentStatus = filter === 100 ? null : filter;
    }
    const res = await request({
      method: 'GET',
      params: {
        ..._params,
      },
    });
    const newList = res.items || [];
    return newList;
  };
  const title = (value: any) => (
    <div className="flex-between-center">
      {/* <img className="nft-cover" src="/logo192.png" /> */}
      <img className="nft-cover" src={REACT_APP_STATIC_URL + value?.nftFile} />
      <div className="flex-1">
        <div className="flex-between-center title-wrapper">
          <span className="card-item-title overflow-ellipsis">
            {value.nftName}
            {/* <span>©</span> */}
          </span>
          {/* <span className="order-status transfer-other">{status}</span> */}
          <span
            className={clsx(
              'order-status',
              statusColor[value?.[type === 'TransferRecord' ? 'transferFilter' : 'status']],
            )}>
            {actions[value?.[type === 'TransferRecord' ? 'transferFilter' : 'status'] + 1].text}
          </span>
        </div>
        {type === 'TransferRecord' ? (
          <div className="row-number">
            <TokenIcon />
            {value?.nftId}
          </div>
        ) : (
          ''
        )}
        <div className="flex-between-center">
          <span className="price">￥{value.amount || value.price || 0}</span>
          <span className="flex-between-center see-details">
            查看详情 <img className="see-details-arrow" src={Arrow} />
          </span>
        </div>
      </div>
    </div>
  );
  const contentRender = (itm: any, value: any) => {
    switch (itm.name) {
      case 'paymentType':
        return <span>{PaymentType[value.type]}</span>;
        break;
      case 'orderTime':
      case 'publicTime':
      case 'transferTime':
        return <span>{formatTime(value[itm.name])}</span>;
        break;

      default:
        return <span>{value[itm.name]}</span>;
        break;
    }
  };
  return (
    <div className="order-record-content">
      {/* <PullToRefresh
        onRefresh={async () => {
          const newList = await fetchData(START_COUNT, LOAD_NUMBER);
          setList(newList);
        }}> */}
      <Collapse className={'order-card-wrapper'}>
        {list.map((item: any, index) => (
          <Collapse.Panel key={item.id || item.nftInfoId} title={title(item)} arrow={null}>
            <div className="detail-list-wrapper">
              {listOption(item).map((itm: any, i: number) => {
                if (itm.label === '所属系列' && !item[itm.name]) return;
                return (
                  <div key={i}>
                    <span>{itm.label}：</span>
                    {itm.isCopy ? (
                      <span className="flex-align-center">
                        <span>{shortenStringStar(item[itm.name], 3, 5)}</span>
                        &nbsp;
                        <Copy toCopy={item[itm.name]} />
                      </span>
                    ) : (
                      contentRender(itm, item)
                    )}
                  </div>
                );
              })}
              {type === 'orderRecord' && item.status === 0 ? (
                <div className="order-pay">
                  <div
                    className="order-pay-div"
                    onClick={async () => {
                      await cancelWeChatPay(item?.orderId);
                      loadMore(true);
                    }}>
                    取消订单
                  </div>
                  <div
                    className="order-pay-div"
                    onClick={async () => {
                      await weChatPay(item?.orderId);
                      loadMore(true);
                    }}>
                    {item.amount === 0 ? '限时领取' : '微信支付'}
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          </Collapse.Panel>
        ))}
      </Collapse>
      <InfiniteScroll loadMore={loadMore} hasMore={isMore} threshold={clientHeight} />
      {/* </PullToRefresh> */}
    </div>
  );
}
