import { request } from 'api';
import moment from 'moment';
import { useCallback, useEffect, useMemo } from 'react';
import { useLocalStorage } from 'react-use';
import storages from 'storages';
import { useUser } from '.';
import { basicUserView, UserState, userViewTypes } from './actions';
import qs from 'querystring';
import { useModalDispatch } from 'contexts/useModal/hooks';
import { Toast } from 'antd-mobile';
import { useNavigate } from 'react-router-dom';
import { isWechat } from 'utils';
const env = process.env.REACT_APP_ENV;
export interface LoginInterface {
  access_token: string;
  expires_in: number;
  token_type: string;
  scope: string;
}
export type LoginType = {
  access_token: string;
  expires: number;
  token_type: string;
  scope: string;
};

export interface MyProfileInterface {
  id: string;
  username: string;
  nickName: string;
  phoneNumber: string;
  email: string;
  address: string;
  isVerifiedName: boolean;
  consigneeName: string;
  consigneePhone: string;
  consigneeAreaId: string;
  consigneeAddress: string;
}
export interface WeChatPersonType {
  city: string;
  code: string | null;
  country: string;
  headimgurl: string;
  nickname: string;
  openid: string;
  phoneNumber: null | string | number;
  privilege: any[];
  province: string;
  sex: number;
  unionid: string;
}

export function useUserDispatch() {
  const [, { dispatch }] = useUser();
  const userDispatch = useMemo(() => {
    return Object.fromEntries(
      Object.keys(basicUserView).map((key) => [
        key,
        (action: UserState) => dispatch(basicUserView[key as userViewTypes].actions(action)),
      ]),
    );
  }, [dispatch]);
  return userDispatch as unknown as { [k in userViewTypes]: Function };
}
// mock login
export const useGetToken = () => {
  const getProfile = useMyProfile();
  const [userInfo] = useUser();
  const [accountInfo, setAccountInfo, remove] = useLocalStorage<LoginType>(storages.accountInfo);
  const [weChatPerson, setWeChatPerson] = useLocalStorage<WeChatPersonType>(storages.weChatPerson);
  useEffect(() => {
    if (accountInfo) {
      accountInfo?.expires?.valueOf() - moment().valueOf() < 3600000;
      // remove();
    }
  }, [accountInfo, remove]);
  const getToken = useCallback(
    async (openid?: string) => {
      const data =
        env === 'dev'
          ? `openid=${'oVL9f5_9jf6PLX6dzP7vRCBQseSo'}&grant_type=wechat&scope=SinodacServer offline_access&client_id=SinodacServer_App`
          : `openid=${
              weChatPerson?.openid || openid
            }&grant_type=wechat&scope=SinodacServer offline_access&client_id=SinodacServer_App`;
      try {
        const result = await request.user.TOKEN({
          method: 'post',
          data: data,
        });
        remove();
        setAccountInfo({ ...result, expires: moment().add(result.expires_in, 'second').valueOf() });
        await request.user.GET_XSRF({ method: 'GET' });
        getProfile();
        request.user.STATISTICS({ method: 'POST' });
        // location.search = '';
      } catch (e) {
        console.log('>>>>', e);
      }
    },
    [getProfile, remove, setAccountInfo, weChatPerson?.openid],
  );
  return getToken;
};

// WeChat login
export const useWeChatLogin = () => {
  const dispatch = useUserDispatch();
  const getVerifyIsBindPhoneNumber = useVerifyIsBindPhoneNumber();
  const modalDispatch = useModalDispatch();
  const [weChatPerson, setWeChatPerson, remove] = useLocalStorage(storages.weChatPerson);
  const getToken = useGetToken();
  const weChatLogin = useCallback(
    async ({ wxCode }: { [x: string]: string }) => {
      try {
        const weChatUserInfo = await request.user.GET_WE_CHAT_AUTH({
          method: 'GET',
          params: {
            code: wxCode,
          },
        });
        dispatch.update({ ...weChatUserInfo });
        setWeChatPerson(weChatUserInfo);
        // await getToken(weChatUserInfo.openid);
        const isVerifyIsBindPhoneNumber = await getVerifyIsBindPhoneNumber(weChatUserInfo.openid);
        if (isVerifyIsBindPhoneNumber) {
          await getToken(weChatUserInfo.openid);
        } else {
          location.href = '/login?type=phone';
          // modalDispatch.setPhoneModal(true);
        }
        return weChatUserInfo;
      } catch (e) {
        console.log('>>>>', e);
        // alert(JSON.stringify(e));
        Toast.show({
          content: '请求超时，请稍后重试',
        });
      }
    },
    [dispatch],
  );
  return weChatLogin;
};

export const useVerifyIsBindPhoneNumber = () => {
  const [userInfo] = useUser();
  const getVerifyIsBindPhoneNumber = useCallback(
    async (openid?: string) => {
      if (!openid) return;
      const res = await request.user.VERIFY_BIND_PHONE({
        method: 'GET',
        query: openid,
      });
      return res;
    },
    [userInfo.openid],
  );
  return getVerifyIsBindPhoneNumber;
};

export const useMyProfile = () => {
  const [userInfo] = useUser();
  const dispatch = useUserDispatch();
  const [isVerifiedName, setIsVerifiedName] = useLocalStorage<boolean>(storages.isVerifiedName);
  // const getConsigneeArea = useAddress();
  const getProfile = useCallback(async () => {
    if (userInfo.id) return;
    const res: MyProfileInterface = await request.user.MY_PROFILE({
      method: 'GET',
    });
    dispatch.update(res);
    setIsVerifiedName(res.isVerifiedName);
    // getConsigneeArea(res?.consigneeAreaId);
  }, [dispatch, userInfo]);
  return getProfile;
};

export const useAddress = () => {
  const dispatch = useUserDispatch();
  const getConsigneeArea = useCallback(
    async (consigneeAreaId: any) => {
      if (!consigneeAreaId) return;
      const res = await request.user.ADDRESS({
        method: 'GET',
        params: {
          areaId: consigneeAreaId,
        },
      });
      dispatch.update({ consigneeAreaId: res?.combinedId });
    },
    [dispatch],
  );
  return getConsigneeArea;
};

type TokenErrorT =
  | 'InvalidGrant_PhoneHasBeenRegister'
  | 'InvalidGrant_PhoneVerifyInvalid'
  | 'OpenIddict_UserLockedOut'
  | 'InvalidGrant_OpenIdNotFound'
  | 'InvalidGrant_PhoneOrTokenCodeNotFound'
  | 'InvalidGrant_OpenIdNotRegister';
interface ITokenError {
  InvalidGrant_PhoneHasBeenRegister: string;
  InvalidGrant_PhoneVerifyInvalid: string;
  OpenIddict_UserLockedOut: string;
  InvalidGrant_OpenIdNotFound: string;
  InvalidGrant_PhoneOrTokenCodeNotFound: string;
  InvalidGrant_OpenIdNotRegister: string;
}
const TokenError: ITokenError = {
  InvalidGrant_PhoneHasBeenRegister: '该手机号已绑定其他微信账号',
  InvalidGrant_PhoneVerifyInvalid: '验证码错误',
  OpenIddict_UserLockedOut: '用户已被禁用',
  InvalidGrant_OpenIdNotFound: '登陆失败，未找到用户OpenId',
  InvalidGrant_PhoneOrTokenCodeNotFound: '登陆失败，未找到用户手机号',
  InvalidGrant_OpenIdNotRegister: '用户未通过微信注册',
};
export const usePhoneLogin = () => {
  const nav = useNavigate();
  const getProfile = useMyProfile();
  const [userInfo] = useUser();
  const [, setAccountInfo, remove] = useLocalStorage<LoginType>(storages.accountInfo);
  const [weChatPerson, setWeChatPerson] = useLocalStorage<WeChatPersonType>(storages.weChatPerson);
  const phoneLogin = useCallback(
    async (verifyCode: string, phone: string) => {
      const data = isWechat()
        ? `openid=${weChatPerson?.openid}&grant_type=wechat&scope=SinodacServer offline_access&client_id=SinodacServer_App&sms_code=${verifyCode}&phone_number=${phone}&headimgurl=${weChatPerson?.headimgurl}&nickname=${weChatPerson?.nickname}`
        : `grant_type=sms_code&scope=SinodacServer offline_access&client_id=SinodacServer_App&sms_code=${verifyCode}&phone_number=${phone}`;
      try {
        const result = await request.user.TOKEN({
          method: 'POST',
          data: data,
        });
        remove();
        setAccountInfo({ ...result, expires: moment().add(result.expires_in, 'second').valueOf() });
        await request.user.GET_XSRF({ method: 'GET' });
        await getProfile();
        await request.user.STATISTICS({ method: 'POST' });
        location.href = '/';
      } catch (error: any) {
        console.log('>>>>', error);
        const error_description: TokenErrorT = error?.response?.data.error_description;
        Toast.show({
          content: TokenError[error_description] || '系统错误，请稍后重试',
        });
        // if (error_description === 'InvalidGrant_PhoneHasBeenRegister') {
        //   Toast.show({
        //     content: '该手机号已被其他账号绑定',
        //   });
        // }else if(error_description === 'InvalidGrant_PhoneVerifyInvalid')
      }
    },
    [getProfile, remove, setAccountInfo, weChatPerson?.headimgurl, weChatPerson?.nickname, weChatPerson?.openid],
  );
  return phoneLogin;
};
export const usePhoneLogin1 = () => {
  const getSinoToken = useSinotoken();
  const [weChatPerson, setWeChatPerson] = useLocalStorage<WeChatPersonType>(storages.weChatPerson);
  const phoneLogin = useCallback(
    async (verifyCode: string, phone: string) => {
      const data = isWechat()
        ? `openid=${weChatPerson?.openid}&grant_type=wechat&scope=SinodacServer offline_access&client_id=SinodacServer_App&sms_code=${verifyCode}&phone_number=${phone}&headimgurl=${weChatPerson?.headimgurl}&nickname=${weChatPerson?.nickname}`
        : `grant_type=sms_code&scope=SinodacServer offline_access&client_id=SinodacServer_App&sms_code=${verifyCode}&phone_number=${phone}`;
      getSinoToken(data);
    },
    [getSinoToken, weChatPerson?.headimgurl, weChatPerson?.nickname, weChatPerson?.openid],
  );
  return phoneLogin;
};
export const useGetToken1 = () => {
  const getSinoToken = useSinotoken();
  const [weChatPerson, setWeChatPerson] = useLocalStorage<WeChatPersonType>(storages.weChatPerson);
  const getToken = useCallback(
    (openid?: string) => {
      const data =
        env === 'dev'
          ? `openid=${'oVL9f5_9jf6PLX6dzP7vRCBQseSo'}&grant_type=wechat&scope=SinodacServer offline_access&client_id=SinodacServer_App`
          : `openid=${
              weChatPerson?.openid || openid
            }&grant_type=wechat&scope=SinodacServer offline_access&client_id=SinodacServer_App`;
      getSinoToken(data);
    },
    [getSinoToken, weChatPerson?.openid],
  );
  return getToken;
};

const useSinotoken = () => {
  const getProfile = useMyProfile();
  const [, setAccountInfo, remove] = useLocalStorage<LoginType>(storages.accountInfo);
  const getSinoToken = useCallback(
    async (data: string) => {
      try {
        const result = await request.user.TOKEN({
          method: 'POST',
          data: data,
        });
        remove();
        setAccountInfo({ ...result, expires: moment().add(result.expires_in, 'second').valueOf() });
        await request.user.GET_XSRF({ method: 'GET' });
        await getProfile();
        await request.user.STATISTICS({ method: 'POST' });
        location.href = '/';
      } catch (error: any) {
        console.log('>>>>', error);
        const error_description: TokenErrorT = error?.response?.data.error_description;
        Toast.show({
          content: TokenError[error_description],
        });
      }
    },
    [getProfile, remove, setAccountInfo],
  );
  return getSinoToken;
};
