import { Warning } from 'assets/images';
import { useModal } from 'contexts/useModal';
import { useModalDispatch } from 'contexts/useModal/hooks';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import CommonModal from '../CommonModal/CommonModal';

import './style.less';
export default function AuthModal() {
  const [{ authModal }] = useModal();
  const nav = useNavigate();
  const { setAuthModal, setVerifiedModal } = useModalDispatch();
  const content = useMemo(() => {
    return (
      <p className="auth-modal-content flex">
        <Warning />
        您当前尚未实名认证,&nbsp; 无法购买藏品!
      </p>
    );
  }, []);
  return (
    <CommonModal
      visible={authModal}
      // visible={true}
      title="实名认证"
      content={content}
      actions={[
        [
          { key: 'cancel', text: '取消', className: 'cancel', onClick: () => setAuthModal(false) },
          {
            key: 'auth',
            text: '去认证',
            className: 'confirm',
            onClick: () => {
              setAuthModal(false);
              // nav('/user/user-info');
              setVerifiedModal(true);
            },
          },
        ],
      ]}
    />
  );
}
