import { useModal } from 'contexts/useModal';
import { useModalDispatch } from 'contexts/useModal/hooks';
import { Verified } from 'pages/User/component/UserInfo/ModalContent';
import { AfterActionParam, UserItemKey } from 'pages/User/types';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CommonModal from '../CommonModal/CommonModal';
import './style.less';

export default function VerifiedModal() {
  const [{ verifiedModal }] = useModal();
  const nav = useNavigate();
  const { setVerifiedModal } = useModalDispatch();
  const afterAction = useCallback(
    (props?: AfterActionParam) => {
      if (props?.type === 'cancel' || props?.type === 'success') return setVerifiedModal(false);
    },
    [setVerifiedModal],
  );
  return (
    <CommonModal className="verified-modal" title={'实名认证'} visible={verifiedModal}>
      <Verified afterAction={afterAction} />
    </CommonModal>
  );
}
