import { Toast } from 'antd-mobile';
import { More, IdentifierIcon, TitleIcon } from 'assets/images';
import clsx from 'clsx';
import SaleCountdown from 'components/SaleCountdown/SaleCountdown';
import { REACT_APP_STATIC_URL } from 'constants/index';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-use';
import { NftStateEnum } from 'types/enum';
import './style.less';
export default function Card({
  width,
  value = {},
  isSeries,
  id,
  nftName,
  creatorName,
  nickName,
  publicTime,
  nftState,
  price,
  isProvideReal,
  nftFile,
  nftProtocolId,
  circulation,
  reserveForLottery,
  salesVolume,
  isPersonalMuseum,
  nftInfoId,
  image,
  productType,
}: {
  width?: number | string;
  value?: any;
  isSeries?: boolean;
  id?: number | string;
  nftName?: string;
  creatorName?: string;
  nickName?: string;
  publicTime?: string;
  nftState?: number;
  price?: number;
  isProvideReal?: boolean;
  nftFile?: string;
  nftProtocolId?: string;
  circulation?: number;
  reserveForLottery?: number;
  salesVolume?: number;
  isPersonalMuseum?: boolean;
  nftInfoId?: string;
  image?: string;
  productType?: number;
}) {
  const { pathname, hash } = useLocation();
  const [time, setTime] = useState(moment(publicTime).valueOf());
  const [tokenId, setTokenId] = useState('');
  const [inSeries, setInSeries] = useState(false);
  const [isBlindBox, setIsBlindBox] = useState(true);
  const [showOrg, setShowOrg] = useState<boolean>(false);
  const [showQuantity, setShowQuantity] = useState<boolean>(false);
  const [quantity, setQuantity] = useState<number | null>(null);
  const nav = useNavigate();

  useEffect(() => {
    switch (pathname) {
      case '/':
        setInSeries(false);
        setShowOrg(true);
        setQuantity(null);
        break;
      case '/personal-museum':
        setInSeries(true);
        setShowOrg(false);
        setQuantity(null);
        setShowQuantity(false);
        break;
      case '/users-museum':
        setInSeries(true);
        setShowOrg(true);
        setQuantity(null);
        setShowQuantity(false);
        // }
        break;
      case '/series':
        setQuantity((circulation ?? 0) - (reserveForLottery ?? 0) - (salesVolume ?? 0));
        setShowQuantity(true);
        break;
      case '/organization':
        setShowOrg(false);
        setQuantity((circulation ?? 0) - (reserveForLottery ?? 0) - (salesVolume ?? 0));
        setShowQuantity(true);
        break;

      default:
        setInSeries(true);
        setShowOrg(false);
        break;
    }
  }, [hash, pathname]);
  const handleCardClick = () => {
    if (nftState === NftStateEnum['已下架'] && pathname === '/users-museum') {
      return Toast.show({
        content: '该藏品已下架，请查看其他藏品',
      });
    }
    if (isSeries) return nav(`/series?id=${id ?? nftProtocolId}`, { state: { id, isSeries } });
    if (nftInfoId && pathname !== '/users-museum')
      return nav('/my-collection', {
        state: {
          // id: id ?? nftProtocolId,
          isSeries,
          pathname,
          hash,
          isPersonalMuseum,
          nftInfoId: nftInfoId,
        },
      });
    return nav(`/detail?id=${id ?? nftProtocolId}`, {
      state: {
        id: id ?? nftProtocolId,
        isSeries,
        pathname,
        hash,
        isPersonalMuseum,
        nftInfoId: nftInfoId,
      },
    });
  };
  const isSmallCard = useMemo(() => {
    return inSeries;
  }, [inSeries]);

  return (
    <div className={clsx('info-card', isSmallCard && 'small')} style={{ width }} onClick={handleCardClick}>
      <div className="part-top">
        <div className={clsx('card-img', isSeries && 'card-img-series')}>
          <img src={REACT_APP_STATIC_URL + image} />
        </div>
        {!quantity && showQuantity && (
          <div className="sold-out-mask flex-center flex-col">
            <p>-已售罄-</p>
          </div>
        )}
        {/* {value.nftActivityType > 0 && value.transactionStatus === 1 ? (
          <div className="sold-out-mask flex-center flex-col">
            <p>-发放中-</p>
          </div>
        ) : (
          ''
        )} */}
        {(value.nftActivityType === 0 || hash === '#received') && value.transactionStatus === 1 ? (
          <div className="sold-out-mask flex-center flex-col">
            <p>-转赠中-</p>
            {/* <p>In transferring</p> */}
          </div>
        ) : (
          ''
        )}

        {inSeries || (
          <SaleCountdown className="top-left-tip" time={time} isSellOut={nftState === NftStateEnum['已售罄']} />
        )}
        {isProvideReal && (
          <p className="bottom-left-tip flex-center">
            <More />
            包含实物
          </p>
        )}
      </div>
      <div className="part-bottom">
        <div className={clsx('row-first width-100 flex-col overflow-ellipsis')}>
          <div className="flex position-relative">
            <p className="title overflow-ellipsis">{nftName}</p>{' '}
            <span className="title-icon">
              &nbsp;
              <img src={TitleIcon} />
            </span>
          </div>
        </div>
        {hash === '#my-pavilion' || hash === '#received' || pathname === '/users-museum' ? (
          <p className="nft-id">
            <img src={IdentifierIcon}></img>
            <span>{value?.nftId}</span>
          </p>
        ) : (
          ''
        )}
        <div className="row-seconde flex-between">
          {showOrg && (
            <p className="author flex-center">
              <span className="overflow-ellipsis">{creatorName}</span>
            </p>
          )}
          {price !== null && Number(price) >= 0 && !isSeries && hash !== '#received' ? (
            <p className="price">¥ {price?.toFixed(2)}</p>
          ) : (
            ''
          )}
          {showQuantity && <p className="quantity flex-center">剩余{quantity}件</p>}
        </div>
      </div>
    </div>
  );
}
