import { ToastShowProps } from 'antd-mobile/es/components/toast';
import { ReactNode } from 'react';

export enum SubmitType {
  ok,
  cancel,
  next,
}

export type UserItemKey = 'nickName' | 'isVerified' | 'phoneNumber' | 'shippingAddress';

export interface UserListValue {
  key: UserItemKey;
  title: string;
  modalTitle: string;
  value: string;
  noClick?: boolean;
}

export interface AfterActionParam extends ToastShowProps {
  type: 'success' | 'fail' | 'loading' | 'cancel';
  title?: ReactNode;
}

export interface UserModalContentProps {
  submitType?: SubmitType;
  afterAction?: (props?: AfterActionParam) => void;
}

export interface UserInfoPopupProps {
  onClose?: () => void;
}
