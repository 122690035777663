import { Toast } from 'antd-mobile';
import { sleep } from 'antd-mobile/es/utils/sleep';
import { request } from 'api';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
type WeChatPayParams = {
  appId: string;
  summary: number;
  timeStamp: string;
  nonceStr: string;
  package: string;
  paySign: string;
  price: number;
  body: string;
};
export const useWechatPay = () => {
  const weChatPay = async (orderId: string, productType?: number, id?: string) => {
    if (!orderId) return;
    try {
      request.sale
        .CREATE_WE_CHAT_PAY({
          method: 'GET',
          params: { orderId: orderId },
        })
        .then((res) => {
          console.log('CREATE_WE_CHAT_PAY', res);
          if (res.price > 0) {
            onBridgeReady(res, productType, orderId);
          } else {
            FREE_GET_NFT(res.outTradeNo);
          }
        });
    } catch (error: any) {
      Toast.show({
        content: error?.response?.data?.error?.message ?? '支付失败，请稍后重试',
      });
    }
  };
  return weChatPay;
};
const FREE_GET_NFT = async (outTradeNo: string) => {
  request.sale
    .FREE_GET_NFT({
      method: 'POST',
      data: { out_trade_no: outTradeNo },
    })
    .then((res) => {
      Toast.clear();
      Toast.show({
        content: '领取成功',
        icon: 'success',
        afterClose: () => {
          location.href = `/personal-museum#my-pavilion`;
        },
      });
    })
    .catch((err) => {
      console.log(err);
      Toast.clear();
      Toast.show({
        content: '领取失败，请稍后重试',
      });
    });
};
const onBridgeReady = (json: WeChatPayParams, productType?: number, orderId?: string) => {
  // const nav = useNavigate();

  WeixinJSBridge.invoke(
    'getBrandWCPayRequest',
    {
      appId: json.appId,
      timeStamp: json.timeStamp,
      nonceStr: json.nonceStr,
      package: json.package,
      signType: 'MD5',
      paySign: json.paySign,
    },
    function (res: any) {
      Toast.clear();
      if (res.err_msg == 'get_brand_wcpay_request:ok') {
        // alert('支付成功,请稍后查询,正在保存订票信息，如有疑问,请联系管理员..');

        // 提交用户数据到数据库操作...

        // location.href = '/Home/MyTicket';
        //不要在这里提交最后的数据到数据库，这里可能不正常（微信团队郑重提示：res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。）
        Toast.show({
          content: '购买成功',
          icon: 'success',
          afterClose: () => {
            if (productType === 1) {
              // nav('/blind-box', { state: { videoSrc: '/1650529780672659.mp4', imgSrc: '/白虎最终稿.png', id: id } });
              // location.href = `/blind-box?orderId=${orderId}`;
              location.href = `/personal-museum#my-pavilion`;
            } else {
              // location.reload();
              location.href = `/personal-museum#my-pavilion`;
            }
          },
        });
      } else if (res.err_msg == 'get_brand_wcpay_request:cancel') {
        // cancel pay
        Toast.show({
          content: '支付取消',
        });
      } else {
        Toast.show({
          content: '购买失败',
        });
      }
    },
  );
};

// cancel pay
export const useCancelWeChatPay = () => {
  const cancelWeChatPay = async (orderId: string) => {
    console.log(orderId);
    try {
      await request.user.CANCEL_ORDER({
        method: 'POST',
        params: { orderId: orderId },
      });
      Toast.show({
        content: '该订单已取消',
      });
    } catch (error: any) {
      Toast.show({
        content: error?.response?.data?.error?.message ?? '取消失败，请稍后重试',
      });
    }
  };
  return cancelWeChatPay;
};
