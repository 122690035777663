import { Button } from 'antd-mobile';
import { Download } from 'assets/images';
import ShareCard, { saveImg } from 'components/ShareCard/ShareCard';
import { useModal } from 'contexts/useModal';
import { useModalDispatch } from 'contexts/useModal/hooks';
import DacShareCard from 'components/DACShareCard/DacShareCard';
import React, { useEffect, useMemo } from 'react';

import './style.less';
import { useLocation } from 'react-use';
export default function ShareModal({
  visible,
  file,
  children,
  onClose,
  image,
}: {
  file?: string;
  visible?: boolean;
  children?: any;
  onClose?: () => void;
  image?: string;
}) {
  const [{ shareModal }] = useModal();
  const modalDispatch = useModalDispatch();
  const { href, pathname, hash, ...other } = useLocation();

  const handleDownload = () => {
    const fileName = 'xx.mp4';
    const x = new XMLHttpRequest();
    x.open('GET', file || '', true);
    x.responseType = 'blob';
    x.onload = (e) => {
      const url = window.URL.createObjectURL(x.response);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      a.click();
    };
    x.send();
  };

  useEffect(() => {
    modalDispatch.setShareModal(false);
  }, [pathname]);

  const isDacShareCard = useMemo(() => {
    return pathname === '/detail' || pathname === '/series' || pathname === '/my-collection';
  }, [pathname]);

  return (
    <div style={{ display: visible || shareModal ? 'flex' : 'none' }} className="share-modal flex-center flex-col">
      {/* {!shareModal ? children : isDacShareCard ? <DacShareCard /> : <ShareCard />} */}
      {/* {!shareModal ? children : isDacShareCard ? <DacShareCard /> : <ShareCard />} */}
      {useMemo(() => {
        return !shareModal ? children : isDacShareCard ? <DacShareCard /> : <ShareCard />;
      }, [shareModal])}

      <div className="save-btn weight-500 flex-center" onClick={saveImg}>
        <Download /> 长按保存图片
      </div>
      <Button
        className="cancel-btn flex-center"
        onClick={() => {
          onClose?.();
          modalDispatch.setShareModal(false);
        }}>
        取消
      </Button>
    </div>
  );
}
