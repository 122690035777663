import { Suspense, useEffect, useMemo, useRef, useState } from 'react';
import { saveAs } from 'file-saver';
import './style.less';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Video from 'components/Video';
import { Button, Mask, Toast } from 'antd-mobile';
import { FingerdownOutline } from 'antd-mobile-icons';
import { useCopyToClipboard } from 'react-use';

export default function Download() {
  const nav = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [file] = useState(searchParams.get('file'));
  const [nftName] = useState(searchParams.get('nftName'));
  const [nftType] = useState(searchParams.get('nftType'));
  const [visible, setVisible] = useState(false);
  const [, setCopied] = useCopyToClipboard();
  const ua = navigator.userAgent;
  console.log(ua);

  //   const { state } = useLocation();
  //   const { file } = state as { file: string };
  useEffect(() => {
    const wechat = ua.includes('MicroMessenger');
    if (wechat) {
      setVisible(true);
    }
  }, []);
  const handleDownload = () => {
    const fileName = `${nftName}`;
    const x = new XMLHttpRequest();

    x.open('GET', file || '', true);
    x.responseType = 'blob';
    x.onload = (e) => {
      const url = window.URL.createObjectURL(x.response);
      console.log(url);
      console.log(e);
      saveAs(url, fileName);
    };

    x.send();
  };
  const isSafari = useMemo(() => {
    if (ua.includes('Safari')) {
      return true;
    }
    return false;
  }, [ua]);
  return (
    <div className="download">
      <Video url={file || ''} autoplay={false}></Video>
      {visible ? (
        ''
      ) : (
        <div onClick={handleDownload} className="save-text">
          保存音/视频
        </div>
      )}
      <Mask visible={visible} opacity="thick">
        <div className="mask-content">
          <div className="mask-content-text">
            请复制
            <FingerdownOutline />
            该链接 ，在手机端（百度/谷歌/Safari）浏览器打开，进行保存
          </div>
          <Button
            fill="outline"
            className="mask-content-button"
            onClick={() => {
              setCopied(window.location.href);
              Toast.show({ content: '已复制' });
            }}>
            复制
          </Button>
        </div>
      </Mask>
    </div>
  );
}
