import Moment from 'moment';

const getMillisecond = (time: any) => {
  const { seconds } = time || {};
  const tim = seconds || time;
  if (String(tim).length <= 10) {
    return tim * 1000;
  }
  if (typeof tim !== 'number') {
    return Number(tim);
  }
  return tim;
};
// const rTime = (timestamp: string) => {
//   let date: any = new Date(new Date(timestamp).getTime() + 8 * 3600 * 1000);
//   date = date.toJSON();
//   date = date.substring(0, 19).replace('T', ' ');
//   return date;
// };
export function formatTime(t: string, formats = 'YYYY-MM-DD HH:mm:ss') {
  if (t && typeof t === 'string' && !t.includes('T') && !t.includes('-'))
    return Moment(getMillisecond(t)).format(formats);
  return Moment(t).format(formats);
}
