import { Button, Input, Swiper, Tabs, Toast } from 'antd-mobile';
import { SwiperRef } from 'antd-mobile/es/components/swiper';
import BaseTitle from 'components/BaseTitle';
import { UserInfoPopupProps } from 'pages/User/types';
import { useCallback, useRef, useState } from 'react';
import { useEffectOnce } from 'react-use';
import './RedemptionCode.less';
import { CloseCircleFill } from 'antd-mobile-icons';
import { request } from 'api';
import RedemptionCodeResultModal from './RedemptionCodeResultModal';
enum TabKeyType {
  verify = 'verify',
  exchange = 'exchange',
}
const tabItems = [
  { key: 'verify', title: '验证' },
  { key: 'exchange', title: '兑换' },
];

export default function RedemptionCode({ onClose }: UserInfoPopupProps) {
  const [activeIndex, setActiveIndex] = useState<number>(9999);
  const [inputVal, setInputVal] = useState<string>();
  const [isValidate, setIsValidate] = useState<boolean>(false);
  const swiperRef = useRef<SwiperRef>(null);
  const [redemptionCodeResult, setRedemptionCodeResult] = useState(-1);
  useEffectOnce(() => {
    setActiveIndex(0);
  });

  const btnClick = useCallback(
    async (type: TabKeyType) => {
      if (!inputVal) return;
      if (type === TabKeyType.verify) {
        console.log('verify');
        try {
          await request.user.VALIDATE_REDEEM_CODE({
            method: 'POST',
            params: {
              Code: inputVal,
            },
          });
          setIsValidate(true);
          setRedemptionCodeResult(0);
        } catch (error: any) {
          console.debug(error);
          const message = error?.response?.data?.error?.message;
          if (message.indexOf('已被兑换') > -1) {
            setRedemptionCodeResult(2);
          } else {
            setRedemptionCodeResult(1);
          }
          setIsValidate(false);
        }
      } else if (type === TabKeyType.exchange) {
        try {
          await request.user.REDEEM({
            method: 'POST',
            data: {
              Code: inputVal,
            },
          });
          setIsValidate(false);
          setRedemptionCodeResult(3);
        } catch (error: any) {
          console.debug(error);
          const message = error?.response?.data?.error?.message;
          if (message.indexOf('已被兑换') > -1) {
            setRedemptionCodeResult(5);
          } else {
            setRedemptionCodeResult(4);
          }
          setIsValidate(true);
        }
        // if (isValidate) {
        // } else {
        //   Toast.show({ content: '请先进行兑换码验证！' });
        // }
      }
    },
    [inputVal],
  );
  const onCloseRedem = () => {
    setRedemptionCodeResult(-1);
  };
  return (
    <div className="popup-content-page redemption-code-wrapper">
      <BaseTitle title="兑换码" goBack={onClose} />
      <div className="redemption-code-content">
        <Tabs
          activeKey={tabItems[activeIndex]?.key ?? 'verify'}
          activeLineMode="fixed"
          onChange={(key: string) => {
            const index = tabItems.findIndex((item) => item.key === key);
            setActiveIndex(index);
            swiperRef.current?.swipeTo(index);
          }}
          style={{
            '--title-font-size': '1.14rem',
            '--active-line-height': '0.14rem',
            '--fixed-active-line-width': '5.7143rem',
          }}>
          {tabItems.map((item) => (
            <Tabs.Tab title={item.title} key={item.key} />
          ))}
        </Tabs>
        <div>
          <Swiper
            className="swiper-content"
            direction="horizontal"
            loop
            indicator={() => null}
            ref={swiperRef}
            defaultIndex={0}
            onIndexChange={(index) => {
              setActiveIndex(index);
            }}>
            <Swiper.Item className="verify-wrapper">
              <Input
                placeholder="请输入兑换码"
                clearable
                onChange={(v) => setInputVal(v)}
                onEnterPress={() => btnClick(TabKeyType.verify)}
              />
              <Button onClick={() => btnClick(TabKeyType.verify)}>验证</Button>
            </Swiper.Item>
            <Swiper.Item className="exchange-wrapper">
              <Input
                placeholder="请输入兑换码"
                clearable
                onChange={(v) => setInputVal(v)}
                onEnterPress={() => btnClick(TabKeyType.exchange)}
              />
              <Button onClick={() => btnClick(TabKeyType.exchange)}>验证</Button>
              <p className="verify-tip">同一兑换码仅可兑换一次，兑换成功后兑换码将失效</p>
            </Swiper.Item>
          </Swiper>
          <RedemptionCodeResultModal
            result={redemptionCodeResult}
            visible={redemptionCodeResult > -1}
            onClose={onCloseRedem}
          />
        </div>
      </div>
    </div>
  );
}
