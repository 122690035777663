import { InfiniteScroll } from 'antd-mobile';
import { request } from 'api';
import { One, Three, Two } from 'assets/images';
import clsx from 'clsx';
import { useUser } from 'contexts/useUser';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import './style.less';
export default function Ranking({ className, ...options }: { className?: string }) {
  const nav = useNavigate();
  const [{ id }] = useUser();
  const [rankingList, setRankingList] = useState([]);
  const [isMore, setHasMore] = useState<boolean>(true);
  const clientHeight = document.querySelector('body')?.clientHeight;
  const iconList = [<img src={One} key="1" />, <img src={Two} key="2" />, <img src={Three} key="3" />];
  const LOAD_NUMBER = 20;
  const START_COUNT = 0;
  useEffect(() => {
    return () => {
      setRankingList([]);
    };
  }, []);
  const loadMore = async () => {
    // throw new Error('Function not implemented.');
    console.log('>>>>');
    // sleep(1000);
    const SkipCount = rankingList ? rankingList.length : START_COUNT;
    const MaxResultCount = LOAD_NUMBER;
    // const _activeKey: string = activeKey || '图片';
    const res = await request.personalMuseum.FAVOURITE_RANKING({
      method: 'GET',
      params: {
        SkipCount,
        MaxResultCount,
      },
    });
    const newList = res.items || [];
    setRankingList((v) => v.concat(newList));
    setHasMore(newList.length === LOAD_NUMBER);
  };
  return (
    <div {...options} className={clsx('ranking-subpage', className)}>
      <div className="ranking-header flex-between">
        <p>收藏家昵称</p>
        <p>藏品数量</p>
      </div>
      <div className="ranking-list">
        {rankingList.map((item: any, index) => {
          return (
            <div
              // className={clsx('item flex-between', id && id === item?.id ? 'self' : '')}
              className={clsx('item flex-between')}
              key={index}
              onClick={() => nav('/users-museum', { state: { id: item?.userId, userName: item?.userName } })}>
              <p className="flex-center">
                <span className="flex-center">{iconList?.[index] || index + 1}</span> {item?.userName}
              </p>
              <p>{item?.nftNumber ?? 0}&nbsp;件</p>
              {/* <p className="flex-center">
                <span className="flex-center">{iconList?.[index] || index + 1}</span> 藏家****456
              </p>
              <p>1234&nbsp;件</p> */}
            </div>
          );
        })}
        <InfiniteScroll loadMore={loadMore} threshold={clientHeight} hasMore={isMore} />
      </div>
    </div>
  );
}
