import { Time } from 'assets/images';
import clsx from 'clsx';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { useInterval } from 'react-use';

import './style.less';
export default function SaleCountdownHome({
  time,
  isSellOut,
  className,
  isSeries,
}: {
  time: number;
  isSellOut: boolean;
  className?: string;
  isSeries?: boolean;
}) {
  const [currentTime, setCurrentTime] = useState(moment().valueOf());
  useInterval(
    () => {
      setCurrentTime(moment().valueOf());
    },
    time > moment().valueOf() ? 1000 : null,
  );
  const renderTime = useMemo(() => {
    const duration = moment.duration(moment(time).diff(currentTime));
    if (duration.asMilliseconds() < 0) return null;
    return moment(time).format('YYYY.MM.DD HH:mm');
    // duration.asHours() > 24
    //   ? moment(time).format('YYYY.MM.DD HH:mm')
    //   : `${duration.hours().toString().padStart(2, '0')}:${duration.minutes().toString().padStart(2, '0')}:${duration
    //       .seconds()
    //       .toString()
    //       .padStart(2, '0')}`;
  }, [currentTime, time]);

  return (
    <>
      {isSellOut ? (
        <p className={clsx('base-sale-countdown-home flex-center', className)}>已售罄</p>
      ) : renderTime ? (
        <>
          <p className="base-sale-countdown-expect">敬请期待</p>
          <p className={clsx('base-sale-countdown-home sale-count-down-home flex-center', className)}>
            <img src={Time}></img>
            开售时间&nbsp;
            {renderTime}
          </p>
        </>
      ) : (
        // <div className="hot-sale">火热进行中</div>
        <div className={clsx('hot-sale', isSeries && 'hot-sale-series')}>火热进行中</div>
      )}
    </>
  );
}
