import { Button, Toast } from 'antd-mobile';
import { ButtonProps } from 'antd-mobile/es/components/button';
import { useEffect, useState } from 'react';
import './CountDownButton.less';
export interface CountDownButtonProps extends ButtonProps {
  delayTime?: number;
  isRefresh?: number;
  firstClick?: () => Promise<void>;
  validate?: () => Promise<boolean | string>;
}
let active: any;
export default function CountDownButton({
  delayTime = 60,
  firstClick,
  validate,
  isRefresh,
  ...props
}: CountDownButtonProps) {
  const [isShowCode, setIsShowCode] = useState<boolean>(false);
  const [text, setText] = useState<string>('获取验证码');
  const [time, setTime] = useState(delayTime);
  const send = async () => {
    if (isShowCode) {
      return;
    }
    const validateResult = await validate?.();
    if (validateResult === true || !validate) {
      setIsShowCode(true);
      active = setInterval(() => {
        setTime((preSecond) => {
          if (preSecond <= 1) {
            setIsShowCode(false);
            active && clearInterval(active);
            return delayTime;
          }
          return preSecond - 1;
        });
      }, 1000);
      firstClick?.();
    }
    if (typeof validateResult === 'string' && validate) {
      Toast.show({
        content: validateResult,
        icon: 'fail',
      });
    }
  };
  useEffect(() => {
    setText('获取验证码');
    setIsShowCode(false);
    setTime(delayTime);
    active && clearInterval(active);
  }, [delayTime, isRefresh]);
  return (
    <Button disabled={isShowCode} onClick={send} {...props}>
      {isShowCode ? `${time} 秒后` : text}
    </Button>
  );
}
