import { Button } from 'antd-mobile';
import { request } from 'api';
import { Download, QrCode } from 'assets/images';
import clsx from 'clsx';
import CardList from 'components/CardList/CardList';
import { useEffect, useState } from 'react';
import { useHash } from 'react-use';
import wx from 'weixin-js-sdk-ts';

import './style.less';
export default function Created({ className, ...options }: { className?: string }) {
  const [haveCreated, setHaveCreated] = useState(false);
  const [hash, setHash] = useHash();
  const url = request.personalMuseum.MY_CREATE;
  // useEffect(() => {
  //   hash === '#created' && (haveCreated || setHash('#' + 'none'));
  // }, [hash, haveCreated, setHash]);
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    const SkipCount = 0;
    const MaxResultCount = 20;
    const res = await url({
      method: 'GET',
      params: {
        SkipCount,
        MaxResultCount,
      },
    });
    const newList = res.items || [];
    setHaveCreated(newList.length);
  };
  return (
    <div {...options} className={clsx('created-subpage', className, haveCreated && 'subpage')}>
      {haveCreated ? (
        <CardList url={url} />
      ) : (
        <div className="not-created flex-col flex-center">
          <p>
            您当前尚未发行数字藏品
            <br />
            申请发行请联系官方微信客服
          </p>
          <img id="customer-service" src={QrCode} />
          <div className="flex-center">
            <Download />
            长按保存图片
          </div>
        </div>
      )}
    </div>
  );
}
