import { basicActions } from 'contexts/utils';

const UserActions = {
  destroy: 'DESTROY',
  update: 'UPDATE',
};

export type UserState = {
  id: string;
  username?: string;
  userName?: string;
  nickName?: string;
  phoneNumber?: string;
  email?: string;
  address: string;
  isVerifiedName: boolean;
  consigneeName?: string;
  consigneePhone: string;
  consigneeAreaId?: string;
  consigneeAddress: string;
  openid: string;
  nickname?: string;
  sex?: number;
  province?: string;
  city?: string;
  country?: string;
  profileImage?: string;
  privilege?: string[];
  unionid?: string;
};

export type userViewTypes = 'destroy' | 'update';

export const basicUserView: {
  [x in userViewTypes]: { type: any; actions: (userInfo: UserState) => { type: string; payload: any } };
} = {
  // setShareModal: {
  //   type: modalActions['setShareModal'],
  //   actions: (shareModal: boolean) => basicActions(modalActions['setShareModal'], { shareModal }),
  // },
  destroy: {
    type: UserActions['destroy'],
    actions: () => basicActions(UserActions['destroy']),
  },
  update: {
    type: UserActions['update'],
    actions: (userInfo: UserState | null) => basicActions(UserActions['update'], userInfo),
  },
};
