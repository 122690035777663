import { Dialog } from 'antd-mobile';
import clsx from 'clsx';
import { DialogProps } from 'antd-mobile/es/components/dialog';
import './style.less';
import { ReactNode } from 'react';

export default function CommonModal(options: DialogProps & { children?: ReactNode; ref?: any }) {
  return (
    <Dialog
      {...options}
      content={options?.children || options?.content}
      bodyClassName={clsx('common-modal', options.bodyClassName)}
    />
  );
}
