import { LogoBlue } from 'assets/images';
// import html2canvas from 'html2canvas';
import { QRCodeSVG } from 'qrcode.react';
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-use';

import './style.less';
const { html2canvas } = window as any;
export const saveImg = () => {
  html2canvas(document.querySelector('#capture') as HTMLElement, { backgroundColor: null }).then((canvas: any) => {
    const dataImg = new Image();
    dataImg.src = canvas.toDataURL('image/png');
    const alink = document.createElement('a');
    alink.href = dataImg.src;
    alink.download = 'lenYan';
    alink.click();
  });
};
export default function ShareCard({ image, isShare }: { image?: string; isShare?: boolean }) {
  const [imgUrl, setImgUrl] = useState('');
  const { href, pathname, hash, ...other } = useLocation();
  console.log('image', image);

  const targetUrl = useMemo(() => {
    switch (pathname) {
      case '/personal-museum':
        return hash === '#ranking' ? href : origin + '/users-museum';
      case '/user':
        return origin;
      default:
        return href;
    }
  }, [hash, href, pathname]);

  useEffect(() => {
    html2canvas(document.querySelector('#capture') as HTMLElement, { backgroundColor: null }).then((canvas: any) => {
      const url = canvas.toDataURL('image/png');
      setImgUrl(url);
    });
  }, [targetUrl]);
  useEffect(() => {
    setImgUrl('');
  }, [href]);

  return (
    <>
      {!imgUrl || isShare ? (
        <div id="capture">
          <img src={image} />
          <div className="share-info flex-between">
            <div className="flex-col width-100">
              <div className="row-1 flex-between">
                <div className="flex-between title-wrap">
                  <img src={LogoBlue} />
                  <div>
                    <p className="title">海河数藏</p>
                    <p className="desc">
                      数字藏品
                      {/* <DCISVG /> */}
                    </p>
                  </div>
                </div>
                <QRCodeSVG
                  value={targetUrl as string}
                  size={48}
                  imageSettings={{
                    src: LogoBlue,
                    width: 12,
                    height: 12,
                    excavate: false,
                  }}
                  bgColor="#fff"
                  fgColor="#000"
                />
              </div>

              {/* <div className="row-2 flex-between">
                <div className="organization">
                  <p>中国文物交流中心</p>
                  <p>中国版权保护中心</p>
                </div>
                <p>唯一官方合作平台</p>
              </div> */}
            </div>
          </div>
        </div>
      ) : (
        <img src={imgUrl} />
      )}
    </>
  );
}
