import { InfiniteScroll, PullToRefresh } from 'antd-mobile';
import { request } from 'api';
import BaseTitle from 'components/BaseTitle';
import { useWindowHeight } from 'hooks/useWindowSize';
import { UserInfoPopupProps } from 'pages/User/types';
import { useEffect, useState } from 'react';
import WatchCard from '../WatchCard';
import './WatchList.less';

export type WatchListType = {
  createTime: string;
  creatorName: string;
  nftFile: string;
  nftName: string;
  nftState: number;
  id: string;
  isSeries: boolean;
};

export default function WatchList({ onClose }: UserInfoPopupProps) {
  const [watchList, setWatchList] = useState<WatchListType[]>([]);
  const clientHeight = useWindowHeight();
  const [isMore, setHasMore] = useState<boolean>(true);
  const LOAD_NUMBER = 20;
  const START_COUNT = 0;
  useEffect(() => {
    return () => {
      setWatchList([]);
    };
  }, []);
  const loadMore = async () => {
    const skipCount = watchList ? watchList.length : START_COUNT;
    const maxResultCount = LOAD_NUMBER;
    const newList = await fetchData(skipCount, maxResultCount);
    setWatchList((v) => v.concat(newList));
    setHasMore(newList.length === LOAD_NUMBER);
  };
  const fetchData = async (skipCount: number, maxResultCount: number) => {
    const res = await request.user.MY_FOLLOW({
      method: 'GET',
      params: {
        skipCount,
        maxResultCount,
      },
    });
    return res.items || [];
  };

  return (
    <div className="user-watch-list-wrapper">
      <BaseTitle title="关注列表" isFixedTop goBack={onClose} />
      <PullToRefresh
        onRefresh={async () => {
          const newList = await fetchData(START_COUNT, LOAD_NUMBER);
          setWatchList(newList);
        }}>
        {watchList?.map((item: WatchListType, index) => (
          <WatchCard key={item?.id ?? index} item={item} {...item} />
        ))}
        <InfiniteScroll loadMore={loadMore} hasMore={isMore} threshold={clientHeight} />
      </PullToRefresh>
    </div>
  );
}
