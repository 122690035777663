import { Clock } from 'assets/images';
import clsx from 'clsx';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { useInterval } from 'react-use';

import './style.less';
export default function SaleCountdown({
  time,
  isSellOut,
  className,
}: {
  time: number;
  isSellOut: boolean;
  className?: string;
}) {
  const [currentTime, setCurrentTime] = useState(moment().valueOf());
  useInterval(
    () => {
      setCurrentTime(moment().valueOf());
    },
    time > moment().valueOf() ? 1000 : null,
  );
  const renderTime = useMemo(() => {
    const duration = moment.duration(moment(time).diff(currentTime));
    if (duration.asMilliseconds() < 0) return null;
    return duration.asHours() > 24
      ? moment(time).format('YYYY.MM.DD HH:mm')
      : `${duration.hours().toString().padStart(2, '0')}:${duration.minutes().toString().padStart(2, '0')}:${duration
          .seconds()
          .toString()
          .padStart(2, '0')}`;
  }, [currentTime, time]);

  return (
    <>
      {isSellOut
        ? // <p className={clsx('base-sale-countdown flex-center', className)}>
          //   <Clock />
          //   已售罄
          // </p>
          ''
        : renderTime && (
            <p className={clsx('base-sale-countdown sale-count-down flex-center', className)}>
              <Clock />
              {renderTime} 开售
            </p>
          )}
    </>
  );
}
