import { request } from 'api';
import clsx from 'clsx';
import CardList from 'components/CardList/CardList';
import UnLoggedIn from 'pages/PersonalMuseum/components/NotloggedIn';
import { useLocalStorage } from 'react-use';
import storages from 'storages';

import './style.less';
export default function MyPavilion({ className, ...options }: { className?: string }) {
  const [accountInfo] = useLocalStorage(storages.accountInfo);
  const url = request.personalMuseum.LIBRARY_LIST;
  return (
    <div {...options} className={clsx('my-pavilion-subpage flex-col', className)}>
      {accountInfo ? <CardList url={url} isPersonalMuseum={true} /> : <UnLoggedIn />}
    </div>
  );
}
