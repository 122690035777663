import clsx from 'clsx';
import { request } from 'api';
import { useState } from 'react';
import './WatchCard.less';
import { Toast } from 'antd-mobile';
import { useNavigate } from 'react-router-dom';
import { REACT_APP_STATIC_URL } from 'constants/index';

export default function WatchCard({
  item,
  nftFile,
  nftName,
  creatorName,
  nftState,
  id,
  isSeries,
}: {
  item: any;
  nftFile: string;
  nftName: string;
  creatorName: string;
  nftState: number;
  id: string;
  isSeries: boolean;
}) {
  const [isFav, setFav] = useState<boolean>(true);
  const nav = useNavigate();
  const followsClick = async () => {
    try {
      const res = await request.nftInfo.FOLLOWS({
        method: 'POST',
        data: {
          isFollow: !isFav,
          isSeries: isSeries,
          nftId: id,
        },
      });
      console.log(res);
      setFav(!isFav);
      Toast.show({
        content: isFav ? '取消关注成功' : '关注成功',
      });
    } catch (error) {
      Toast.show({
        content: isFav ? '取消关注失败' : '关注成功',
      });
      console.debug(error, '==error');
    }
  };
  const handleCardClick = () => {
    if (isSeries) return nav(`/series?id=${id}`, { state: { id, isSeries } });
    return nav(`/detail?id=${id}`, {
      state: {
        id: id,
        isSeries,
        pathname: '/user',
      },
    });
  };
  return (
    <div className="flex-between-center watch-list-item" onClick={handleCardClick}>
      <img className="watch-item-cover" src={REACT_APP_STATIC_URL + nftFile} onClick={handleCardClick} />
      <div className="flex-1 flex-between-center watch-list-detail">
        <div className="info" onClick={handleCardClick}>
          <h4>
            <span className="title">{nftName}</span>
            {/* <span className="verify">©</span> */}
          </h4>
          <p className="introduce">{creatorName}</p>
        </div>
        <div
          className={clsx('fav-wrapper', isFav && 'is-fav')}
          onClick={(e) => {
            e.stopPropagation();
            followsClick();
          }}></div>
      </div>
    </div>
  );
}
