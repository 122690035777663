import { ActionSheet } from 'antd-mobile';
import Copy from 'components/Copy';
import { useUser } from 'contexts/useUser';
import { useState } from 'react';
import { shortenStringStar } from 'utils';
import './UserTem.less';
import type { Action } from 'antd-mobile/es/components/action-sheet';
import { useLocalStorage } from 'react-use';
import storages from 'storages';
import { WeChatPersonType } from 'contexts/useUser/hooks';
export default function UserTem() {
  const [{ nickName, phoneNumber, address, profileImage }] = useUser();
  const [weChatPerson] = useLocalStorage<WeChatPersonType>(storages.weChatPerson);
  console.log('useUser', useUser());
  console.log('weChatPerson', weChatPerson);
  const [visible, setVisible] = useState(false);
  const actions: Action[] = [
    { text: '查看大图', key: 'big' },
    { text: '切换头像', key: 'change' },
    { text: '保存图片', key: 'save' },
  ];
  return (
    <div className="flex-between user-top-wrapper">
      <img onClick={() => setVisible((v) => !v)} src={profileImage ?? weChatPerson?.headimgurl} />
      <div className="flex-1 ">
        <p className="username overflow-ellipsis">{nickName ?? weChatPerson?.nickname ?? '--'}</p>
        <p className="tel-phone">{phoneNumber ? shortenStringStar(phoneNumber, 3, 3) : '--'}</p>
        <p className="flex-align-center block-address">
          <span>{address ? shortenStringStar(address, 4, 8) : '--'}</span>
          {address && <Copy toCopy={address} />}
        </p>
      </div>
      {/* <ActionSheet className="avatar" visible={visible} actions={actions} onClose={() => setVisible(false)} /> */}
    </div>
  );
}
