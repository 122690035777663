export default {
  // contractsFileDescriptorBase64: 'contracts-file-descriptor-base64',
  accountInfo: 'account-info',
  isVerifiedName: 'verified-name',
  weChatPerson: 'we-chat-person',
  XSRF: 'XSRF-TOKEN',
  homeScrollTop: 'HOME_SCROLL_TOP',
  homeActiveTab: 'HOME_ACTIVE_TAB',
  shareInfo: 'share-info',
};
