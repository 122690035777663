import { Toast } from 'antd-mobile';
import clsx from 'clsx';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-use';
import './style.less';
import SaleCountdownHome from 'components/SaleCountdownHome/SaleCountdownHome';
import { TitleIcon, Left, Right, Free } from 'assets/images';
import { NftStateEnum } from 'types/enum';
import { REACT_APP_STATIC_URL } from 'constants/index';
export default function HomeCard({
  width,
  value = {},
  isSeries,
  id,
  nftName,
  creatorName,
  nickName,
  publicTime,
  nftState,
  price,
  isProvideReal,
  nftFile,
  nftProtocolId,
  circulation,
  reserveForLottery,
  salesVolume,
  isPersonalMuseum,
  nftInfoId,
  image,
  productType,
  nftInfoCount,
}: {
  width?: number | string;
  value?: any;
  isSeries?: boolean;
  id?: number | string;
  nftName?: string;
  creatorName?: string;
  nickName?: string;
  publicTime?: string;
  nftState?: number;
  price?: number;
  isProvideReal?: boolean;
  nftFile?: string;
  nftProtocolId?: string;
  circulation?: number;
  reserveForLottery?: number;
  salesVolume?: number;
  isPersonalMuseum?: boolean;
  nftInfoId?: string;
  image?: string;
  productType?: number;
  nftInfoCount?: number;
}) {
  const { pathname, hash } = useLocation();
  const [time, setTime] = useState(moment(publicTime).valueOf());
  const nav = useNavigate();

  const handleCardClick = () => {
    if (nftState === NftStateEnum['已下架'] && pathname === '/users-museum') {
      return Toast.show({
        content: '该藏品已下架，请查看其他藏品',
      });
    }
    if (isSeries) return nav(`/series?id=${id ?? nftProtocolId}`, { state: { id, isSeries } });
    return nav(`/detail?id=${id ?? nftProtocolId}`, {
      state: {
        id: id ?? nftProtocolId,
        isSeries,
        pathname,
        hash,
        isPersonalMuseum,
        nftInfoId: nftInfoId,
      },
    });
  };

  return (
    <div className={clsx('info-card-home')} style={{ width }} onClick={handleCardClick}>
      <div className={clsx('info-card-home-box')}>
        <img src={Left} className="info-card-home-box-border" onClick={() => false} />
        <img src={Right} className="info-card-home-box-border" onClick={() => false} />
        <div className="part-img">
          <img
            className={clsx('card-img', isSeries && 'card-img-series')}
            src={!productType && image ? REACT_APP_STATIC_URL + image : REACT_APP_STATIC_URL + nftFile}
            onClick={() => false}
          />
          {nftState === NftStateEnum['已售罄'] && (
            <div className="sold-out-mask flex-center flex-col">
              <div className="sold-out-mask-content">
                <p>- 已售罄，期待下次发行 -</p>
              </div>
            </div>
          )}
        </div>
        <div className="part-content">
          <div className="flex position-relative">
            <p className="title overflow-ellipsis">
              {nftName}
              <span className="title-icon">
                &nbsp;
                <img src={TitleIcon} />
              </span>
            </p>
          </div>
          <div className="position-relative">
            {/* <span className="series-name">{isSeries ? '#2022年画精选系列' : '#传统文化'}</span> */}
            <span>
              <span className="limit">限量</span>
              <span className="number">{nftInfoCount}份</span>
            </span>
          </div>
          <div className="row-seconde flex-between">
            <p className="author flex-center">
              {isSeries ? null : (
                <>
                  <span>发行方： </span>
                  <span className="overflow-ellipsis">{creatorName}</span>
                </>
              )}
            </p>
          </div>
          {nftState !== NftStateEnum['已售罄'] ? (
            <>
              <div className="line"></div>
              <div className="row-seconde flex-between nft-status">
                <div>
                  <SaleCountdownHome
                    className="top-left-tip"
                    time={time}
                    isSellOut={nftState === NftStateEnum['已售罄']}
                    isSeries={isSeries}
                  />
                </div>
                {price !== null && Number(price) >= 0 && !isSeries && hash !== '#received' ? (
                  <p className="price">
                    {/* <span className="dollar">¥</span> {price?.toFixed(2)} */}
                    {Number(price) > 0 ? (
                      <>
                        <span className="dollar">¥</span> {price?.toFixed(2)}{' '}
                      </>
                    ) : (
                      <span className="free-buy">
                        {/* 免费领取 */}
                        <span className="free">
                          <img src={Free} />
                        </span>
                      </span>
                    )}
                  </p>
                ) : (
                  ''
                )}
              </div>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
}
