import { request } from 'api';
import { useCallback } from 'react';

export const useSendSmsCode = () => {
  const getSendSmsCode = async (phoneNumber: string, type: string) => {
    if (!phoneNumber) return;
    const res = await request.user.SEND_SMS({
      method: 'POST',
      data: {
        phoneNumber: phoneNumber,
        type: type,
      },
    });
    console.log('SEND_SMS', res);
  };
  return getSendSmsCode;
};
export const useCheckSmsCode = () => {
  const checkSmsCode = async (phoneNumber: string, code: string) => {
    if (!phoneNumber || !code) return;
    const res = await request.user.CHECK_SMS({
      method: 'POST',
      data: {
        phoneNumber: phoneNumber,
        code: code,
      },
    });
    console.log('checkSmsCode', res);
  };
  return checkSmsCode;
};
