import { Toast } from 'antd-mobile';
import { ToastProps } from 'antd-mobile/es/components/toast/toast';
import { FailToast, SuccessToast } from 'assets/images';
import './BaseToast.less';

const toolTip = {
  ...Toast,
  show: (props: ToastProps) => {
    let icon = props?.icon;
    if (props?.icon === 'success') {
      icon = <SuccessToast />;
    } else if (props?.icon === 'fail') {
      icon = <FailToast />;
    }
    Toast.show({ ...props, maskClassName: `base-toast-mask ${props?.maskClassName ?? ''}`, icon });
  },
};

export default function BaseToast() {
  return toolTip;
}
