import { Toast } from 'antd-mobile';
import { request } from 'api';
import {
  BackBtn,
  Collect,
  CollectFill,
  HeaderLogo,
  ShareIconBlack,
  ShareIconWhite,
  PlatformShare,
} from 'assets/images';
import clsx from 'clsx';
import { useModalDispatch } from 'contexts/useModal/hooks';
import { useUser } from 'contexts/useUser';
import { useUserDispatch } from 'contexts/useUser/hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage, useLocation } from 'react-use';
import { useLocation as useLocationRouter } from 'react-router-dom';
import storages from 'storages';
const env = process.env.REACT_APP_ENV;

import './style.less';
import ShareModal from 'components/Modal/ShareModal/ShareModal';
export default function Header({ header }: { header?: boolean }) {
  const [{ id, phoneNumber, isVerifiedName }] = useUser();
  const nav = useNavigate();
  const modalDispatch = useModalDispatch();
  const userDispatch = useUserDispatch();
  const { pathname } = useLocation();
  const { state = {} } = useLocationRouter();
  const title = '海河数藏';
  const [showBack, setShowBack] = useState(false);
  const [transparent, setTransparent] = useState(false);
  const [showCollect, setShowCollect] = useState(false);
  const [showTitle, setShowTitle] = useState(false);
  const [showShare, setShowShare] = useState(true);
  const [hideHeader, setHeader] = useState(false);
  const [pageTitle, setPageTitle] = useState('');
  const [isCollected, setIsCollected] = useState(true);
  const [showShareModal, setShareModal] = useState(false);

  const [accountInfo, remove] = useLocalStorage(storages.accountInfo);
  const notLogin = useMemo(() => {
    return env === 'dev' ? !accountInfo : pathname === '/' && !accountInfo;
  }, [accountInfo, pathname]);

  useEffect(() => {
    if (notLogin) {
      // userDispatch.update(null);
      // modalDispatch.setLoginModal(true);
    }
  }, [modalDispatch, notLogin, userDispatch]);

  useEffect(() => {
    if (!notLogin && id && !isVerifiedName) {
      console.log('?isVerifiedName??', !notLogin, id, !isVerifiedName);
      // modalDispatch.setAuthModal(true);
    }
    if (!notLogin && id && !phoneNumber) {
      // nav('/auth');
    }
  }, [id, isVerifiedName, modalDispatch, notLogin, phoneNumber]);

  useEffect(() => {
    Toast.clear();
    setShowBack(false);
    setTransparent(false);
    setShowCollect(false);
    setShowTitle(false);
    setHeader(false);
    setIsCollected(false);
    setShowShare(true);
    setPageTitle('');

    switch (pathname) {
      case '/series':
      case '/detail':
      case '/my-collection':
        setShowBack(true);
        setTransparent(true);
        setShowCollect(true);
        setShowShare(false);
        break;
      case '/users-museum': {
        const { userName } = state as { userName?: string };
        setShowTitle(true);
        setShowBack(true);
        setPageTitle(`${userName}的博物馆`);
        break;
      }
      case '/organization': {
        setShowTitle(true);
        setShowBack(true);
        setPageTitle('机构/艺术家');
        break;
      }
      case '/search-hash': {
        // setShowShare(false);
        setShowBack(true);
        setTransparent(true);
        setShowCollect(true);
        setShowShare(false);
        break;
      }
      case '/':
      case '/user/user-info':
      case '/full-screen':
      case '/user/order-record':
      case '/user/watch-list':
      case '/user/transfer-record':
      case '/auth':
      case '/login':
      case '/code':
      case '/download':
      case '/wechat-tip':
      case '/personal-museum':
        setHeader(true);
        break;
    }
  }, [pathname]);

  const handelCollect = useCallback(async () => {
    if (accountInfo) {
      setIsCollected(!isCollected);
    } else {
      modalDispatch.setLoginModal(true);
    }
  }, [accountInfo, isCollected, modalDispatch]);
  const backIcon = useMemo(() => {
    return (
      <div
        className={clsx('btn-icon flex-center', transparent && 'transparent-icon')}
        onClick={() => {
          history.length === 1 ? nav('/') : nav(-1);
        }}>
        <BackBtn />
      </div>
    );
  }, [transparent, nav]);
  const collectIcon = useMemo(() => {
    return (
      <div onClick={handelCollect} className={clsx('btn-icon flex-center', transparent && 'transparent-icon')}>
        {isCollected ? <CollectFill /> : <Collect />}
      </div>
    );
  }, [handelCollect, isCollected, transparent]);

  const shareIcon = useMemo(() => {
    return (
      <div className={clsx('btn-icon flex-center', transparent && 'transparent-icon')}>
        {/* <img onClick={() => modalDispatch.setShareModal(true)} src={transparent ? ShareIconWhite : ShareIconBlack} /> */}
        <img onClick={() => setShareModal(true)} src={transparent ? ShareIconWhite : ShareIconBlack} />
      </div>
    );
  }, [transparent, modalDispatch]);

  return (
    <div className={clsx('app-header', transparent && 'transparent', hideHeader && !header && 'hide-header')}>
      <div className="part-left">
        {showBack ? (
          backIcon
        ) : (
          <>
            <img className="logo" src={HeaderLogo} />
            {/* <span className="title">{title}</span> */}
          </>
        )}
      </div>
      {showTitle ? <div>{pageTitle}</div> : ''}
      <div className="flex">
        {/* {showCollect && collectIcon} */}
        {showShare && shareIcon}
      </div>
      <ShareModal visible={showShareModal} onClose={() => setShareModal(false)}>
        <div>
          <img src={PlatformShare} />
        </div>
      </ShareModal>
    </div>
  );
}
