import { MainActive, MainInactive, MuseumActive, MuseumInactive, UserActive, UserInactive } from 'assets/images';
import clsx from 'clsx';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation, usePrevious } from 'react-use';

const footerList = [
  { key: '/', text: '首页', activeIcon: <MainActive />, inActiveIcon: <MainInactive /> },
  {
    key: '/personal-museum',
    text: '个人博物馆',
    activeIcon: <MuseumActive />,
    inActiveIcon: <MuseumInactive />,
  },
  { key: '/user', text: '我的', activeIcon: <UserActive />, inActiveIcon: <UserInactive /> },
];

import './style.less';
export default function Footer() {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const hidden = () => {
    if (pathname?.search('search-hash') && pathname?.search('search-hash') > -1) {
      return true;
    }
    switch (pathname) {
      case '/series':
      case '/detail':
      case '/my-collection':
      case '/users-museum':
      case '/user/user-info':
      case '/user/order-record':
      case '/user/watch-list':
      case '/user/transfer-record':
      case '/organization':
      case '/full-screen':
      case '/wechat-tip':
      case '/auth':
      case '/login':
      case '/code':
      case '/download':
      case '/search-hash':
      case '/blind-box':
        return true;

      default:
        return false;
    }
  };
  return (
    <div className={clsx('app-footer', hidden() && 'hidden')}>
      {footerList.map((item) => (
        <p
          className={clsx('flex-col flex-center', pathname?.split('/')?.[1] === item.key.split('/')?.[1] && 'active')}
          key={item.key}
          onClick={() => {
            if (pathname === item.key) return;
            navigate(item.key);
          }}>
          {pathname?.split('/')?.[1] === item.key.split('/')?.[1] ? item.activeIcon : item.inActiveIcon}
          {item.text}
        </p>
      ))}
    </div>
  );
}
