import { Warning } from 'assets/images';
import { useModal } from 'contexts/useModal';
import { useModalDispatch } from 'contexts/useModal/hooks';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import CommonModal from '../CommonModal/CommonModal';

import './style.less';
export default function PhoneModal() {
  const [{ phoneModal }] = useModal();
  const nav = useNavigate();
  const { setPhoneModal } = useModalDispatch();
  const content = useMemo(() => {
    return (
      <p className="auth-modal-content flex">
        <Warning />
        您当前尚未绑定手机号,&nbsp; 请先进行绑定!
      </p>
    );
  }, []);
  return (
    <CommonModal
      visible={phoneModal}
      style={{ zIndex: 1001 }}
      // visible={true}
      title="绑定手机号"
      content={content}
      actions={[
        [
          { key: 'cancel', text: '跳过', className: 'cancel', onClick: () => setPhoneModal(false) },
          {
            key: 'auth',
            text: '去绑定',
            className: 'confirm',
            onClick: () => {
              setPhoneModal(false);
              nav('/login?type=phone');
            },
          },
        ],
      ]}
    />
  );
}
