import { Error, Success } from 'assets/images';
import CommonModal from 'components/Modal/CommonModal/CommonModal';
import { useState } from 'react';

export default function TransferResultModal({
  result,
  visible,
  onClose,
}: {
  result: number;
  visible?: boolean;
  onClose?: () => void;
}) {
  const [modalContent] = useState([
    {
      title: '验证成功',
      content: (
        <div className="flex">
          <p>
            <Success />
          </p>
          验证成功，此兑换码有效且仍未兑换
        </div>
      ),
    },
    {
      title: '验证失败',
      content: (
        <div className="flex">
          <p>
            <Error />
          </p>
          验证失败，此兑换码无效
        </div>
      ),
    },
    {
      title: '验证失败',
      content: (
        <div className="flex">
          <p>
            <Error />
          </p>
          验证失败，此兑换码已被兑换
        </div>
      ),
    },
    {
      title: '兑换成功',
      content: (
        <div className="flex">
          <p>
            <Success />
          </p>
          您已兑换成功
        </div>
      ),
    },
    {
      title: '兑换失败',
      content: (
        <div className="flex">
          <p>
            <Error />
          </p>
          兑换失败，此兑换码无效
        </div>
      ),
    },
    {
      title: '兑换失败',
      content: (
        <div className="flex">
          <p>
            <Error />
          </p>
          兑换失败，此兑换码已被兑换
        </div>
      ),
    },
  ]);
  return (
    <CommonModal
      bodyClassName="transfer-res-modal"
      visible={visible}
      {...modalContent[result]}
      actions={[{ text: '确认', key: 'confirm', className: 'cancel', onClick: onClose }]}
    />
  );
}
