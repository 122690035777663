import { BasicActions } from 'contexts/utils';
import React, { createContext, useContext, useMemo, useReducer } from 'react';
import { basicUserView, UserState } from './actions';

const INITIAL_STATE: UserState | null = null;

const UserContext = createContext<any>(INITIAL_STATE);

export function useUser(): [UserState, BasicActions] {
  return useContext(UserContext);
}

//reducer
function reducer(state: any, { type, payload }: any) {
  console.log('type>>>>>', type);
  switch (type) {
    case basicUserView.destroy.type: {
      return {};
    }
    default: {
      console.log('reducer>>>>>', state, payload);
      return payload ? Object.assign({}, state, payload) : null;
    }
  }
}

export default function Provider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  return (
    <UserContext.Provider value={useMemo(() => [{ ...state }, { dispatch }], [state, dispatch])}>
      {children}
    </UserContext.Provider>
  );
}
