import axios from 'axios';
import storages from 'storages';
import { BaseConfig, requestConfig } from './types';
import Cookies from 'js-cookie';
import { Toast } from 'antd-mobile';

const axiosInstance = axios.create({
  baseURL: '/',
  timeout: 50000,
});

axiosInstance.defaults.headers.common['x-csrf-token'] = 'AUTH_TOKEN';

axiosInstance.interceptors.request.use(
  (config) => {
    const token = JSON.parse(localStorage.getItem(storages.accountInfo) || '{}').access_token;
    const RequestVerificationToken = Cookies.get(storages.XSRF) || '';
    const AspNetCoreCulture = Cookies.get('.AspNetCore.Culture') || '';
    if (token) {
      config.headers = { ...config.headers, Authorization: `Bearer ${token}` };
    }
    if (RequestVerificationToken) {
      config.headers = { ...config.headers, RequestVerificationToken };
    }
    if (!AspNetCoreCulture) {
      Cookies.set('.AspNetCore.Culture', 'c=zh-Hans|uic=zh-Hans');
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  (response) => {
    const { config, status, data } = response;
    if (config.url === '/api/app/accounts/person' && status === 204) {
      localStorage.removeItem(storages.accountInfo);
    }
    const res = data;
    return res;
  },
  (error) => {
    const { status } = error.response;
    if (status === 401) {
      Cookies.remove(storages.XSRF);
      localStorage.removeItem(storages.accountInfo);
      localStorage.removeItem(storages.weChatPerson);
      Toast.show({
        content: '请求超时，请稍后重试',
      });
    }
    return Promise.reject(error);
  },
);

export const service = axiosInstance;

export function spliceUrl(baseUrl: string, extendArg?: string) {
  return extendArg ? baseUrl + '/' + extendArg : baseUrl;
}

export function getRequestConfig(base: BaseConfig, config?: requestConfig) {
  if (typeof base === 'string') {
    return config;
  } else {
    const { baseConfig } = base || {};
    const { query, method, params, headers } = config || {};
    return {
      ...config,
      query: (baseConfig.query || '') + (query || ''),
      method: method ? method : baseConfig.method,
      params: Object.assign({}, baseConfig.params, params),
      headers: Object.assign({}, baseConfig.headers, headers),
    };
  }
}
