import { Tabs } from 'antd-mobile';
import { useCallback, useMemo, useState } from 'react';
import { useEffectOnce, useHash, useLocalStorage } from 'react-use';
import storages from 'storages';
import './style.less';
import Created from './Subpages/Created/Created';
import MyPavilion from './Subpages/MyPavilion/MyPavilion';
import Ranking from './Subpages/Ranking/Ranking';
import Received from './Subpages/Received/Received';
export default function PersonalMuseum() {
  const [hash, setHash] = useHash();
  const [accountInfo] = useLocalStorage(storages.accountInfo);
  const handelHashChange = useCallback(
    (e: string) => {
      setHash('#' + e);
    },
    [setHash],
  );
  useEffectOnce(() => {
    if (!hash) {
      handelHashChange('my-pavilion');
    }
  });
  return (
    <div className="museum-page flex-col">
      <Tabs
        onChange={handelHashChange}
        defaultActiveKey={
          !accountInfo ? 'ranking' : hash.replace('#', '').length ? hash.replace('#', '') : 'my-pavilion'
        }>
        {accountInfo ? (
          <Tabs.Tab title="我的展馆" key="my-pavilion">
            <MyPavilion className="subpage" />
          </Tabs.Tab>
        ) : (
          ''
        )}
        <Tabs.Tab title="收藏排行" key="ranking">
          <Ranking className="subpage" />
        </Tabs.Tab>
        {accountInfo ? (
          <>
            <Tabs.Tab title="收到转赠" key="received">
              <Received className="subpage" />
            </Tabs.Tab>
            <Tabs.Tab title="我的发行" key="created">
              <Created />
            </Tabs.Tab>
          </>
        ) : (
          ''
        )}
      </Tabs>
    </div>
  );
}
