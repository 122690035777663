import AuthModal from './AuthModal/AuthModal';
import LoginModal from './LoginModal/LoginModal';
import ShareModal from './ShareModal/ShareModal';
import PhoneModal from './PhoneModal/PhoneModal';
import VerifiedModal from './VerifiedModal/VerifiedModal';

export default function Modals() {
  return (
    <>
      <LoginModal />
      <AuthModal />
      <ShareModal />
      <PhoneModal />
      <VerifiedModal />
    </>
  );
}
