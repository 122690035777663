import { InfiniteScroll, Collapse, Switch } from 'antd-mobile';
import { CollapsePanel } from 'antd-mobile/es/components/collapse/collapse';
import { sleep } from 'antd-mobile/es/utils/sleep';
import { request } from 'api';
import clsx from 'clsx';
import Card from 'components/Card/Card';
import { useEffect, useMemo, useState } from 'react';
import { NftType } from 'types/enum';
import './style.less';
export default function CardList({
  url,
  params,
  isPersonalMuseum,
}: {
  url?: any;
  params?: any;
  isPersonalMuseum?: boolean;
}) {
  const [sortBuyTime, setSortBuyTime] = useState(true);
  const [activeKey, setActiveKey] = useState<string[]>([]);
  const [types, setTypes] = useState(['图片', '音频', '视频', '3D模型']);
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [isMore, setHasMore] = useState<boolean>(true);
  const clientHeight = document.querySelector('body')?.clientHeight;
  const LOAD_NUMBER = 20;
  const START_COUNT = 0;
  useEffect(() => {
    return () => {
      setList([]);
    };
  }, []);
  const sortBtn = useMemo(() => {
    return (
      <div className="header">
        <div className="total-count">
          已收藏 <span>{total}</span> 件
        </div>
        {/* <Switch
          uncheckedText="时间"
          checkedText="类型"
          checked={sortBuyTime}
          onChange={(value: boolean) => {
            setSortBuyTime(value);
          }}
        /> */}
        <div
          className="sort-btn"
          onClick={() => {
            setSortBuyTime((v) => !v);
          }}>
          <span className={clsx(sortBuyTime && 'active')}>时间</span>
          <span className={clsx(sortBuyTime || 'active')}>类型</span>
        </div>
        {/* <div
          className="sort-btn flex"
          onClick={() => {
            setSortBuyTime((v) => !v);
          }}>
          <p className={clsx('flex-center', sortBuyTime && 'active')}>
            <Clock /> 按时间排序
          </p>
          <p className={clsx('flex-center', sortBuyTime || 'active')}>
            <Type /> 按类型排序
          </p>
        </div> */}
      </div>
    );
  }, [sortBuyTime, total]);
  const loadMore = async (a?: string) => {
    const SkipCount = list ? list.length : START_COUNT;
    const MaxResultCount = LOAD_NUMBER;
    const res = await url({
      method: 'GET',
      params: {
        SkipCount,
        MaxResultCount,
        ...params,
      },
    });
    const newList = res.items || [];
    setList((v) => v.concat(newList));
    setTotal(res.totalCount);
    setHasMore(newList.length === LOAD_NUMBER);
  };
  const infinityList = useMemo(() => {
    return (
      <>
        <div className="list">
          {list.map((item: any, index) => (
            <Card key={index} value={item} {...item} isPersonalMuseum={isPersonalMuseum} />
          ))}
        </div>
        <InfiniteScroll loadMore={loadMore} threshold={clientHeight} hasMore={isMore} />
      </>
    );
  }, [clientHeight, isMore, list]);
  const collapseChange = (key: string[]) => {
    setActiveKey(key);
  };
  const typeList = useMemo(() => {
    return (
      <>
        {types.map((item, index) => {
          if (item === NftType[index]) {
            return (
              <Collapse key={item} onChange={collapseChange}>
                <CollapsePanel key={item} title={item}>
                  <div className="list">
                    {list.map((itm: any, i: number) => {
                      if (itm.nftType === index) {
                        return <Card key={i} {...itm} value={itm} isPersonalMuseum={isPersonalMuseum} />;
                      }
                    })}
                  </div>
                  <InfiniteScroll loadMore={() => loadMore('aa')} threshold={clientHeight} hasMore={isMore} />
                </CollapsePanel>
              </Collapse>
            );
          }
        })}
      </>
    );
  }, [list, types]);

  return (
    <div className="personal-card-list flex-1">
      {sortBtn}
      {sortBuyTime ? infinityList : typeList}
    </div>
  );
}
