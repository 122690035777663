import { ReactNode } from 'react';
import './BaseTitle.less';
import { BackBtn } from 'assets/images';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
interface BaseTitleProps {
  title: ReactNode;
  extra?: ReactNode;
  isFixedTop?: boolean;
  goBack?: () => void;
}

export default function BaseTitle({ title, extra, isFixedTop, goBack }: BaseTitleProps) {
  const navigate = useNavigate();
  return (
    <div className="base-title-wrapper">
      <h3 className={clsx('base-title', isFixedTop && 'title-fixed-top')}>
        <BackBtn
          className="back-icon"
          onClick={() => {
            !goBack && navigate('/user');
            goBack?.();
          }}
        />
        {title}
        <div className="base-title-extra">{!!extra && extra}</div>
      </h3>
    </div>
  );
}
