import { Download, QrCode } from 'assets/images';
import BaseTitle from 'components/BaseTitle';
import { UserInfoPopupProps } from 'pages/User/types';
import './ContactUs.less';

export default function ContactUs({ onClose }: UserInfoPopupProps) {
  return (
    <div className="popup-content-page contact-us-wrapper">
      <BaseTitle title="联系我们" goBack={onClose} />
      <div className="contact-us-content">
        <h6>微信扫一扫，联系官方客服</h6>
        <img className="qr-code" src={QrCode} />
        {/* <img className="qr-code" src="/logo192.png" /> */}
        <div className="flex-align-center save-btn">
          <Download /> 长按保存图片
        </div>
      </div>
    </div>
  );
}
