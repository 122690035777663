import { Popup } from 'antd-mobile';
import BaseTitle from 'components/BaseTitle';
import { useMemo } from 'react';
import './ProtocolPopup.less';
import { serviceProtocol, privacyProtocol } from './content';
export enum ProtocolType {
  privacyProtocol = 'privacyProtocol',
  serviceProtocol = 'serviceProtocol',
}
interface ProtocolPopupProps {
  Protocol?: ProtocolType | boolean;
  onClose?: () => void;
}
const pageTextMap = {
  [ProtocolType.serviceProtocol]: {
    text: serviceProtocol(),
    title: '用户服务协议',
  },
  [ProtocolType.privacyProtocol]: {
    text: privacyProtocol(),
    title: '隐私权政策',
  },
};

export default function ProtocolPopup({ Protocol, onClose }: ProtocolPopupProps) {
  const pageText = useMemo(() => {
    if (!Protocol) return null;
    return (
      <div className="protocol-wrapper">
        <BaseTitle title={pageTextMap[Protocol as ProtocolType]?.title} goBack={() => onClose?.()} />
        <div className="introduce">{pageTextMap[Protocol as ProtocolType]?.text}</div>
      </div>
    );
  }, [Protocol, onClose]);

  return (
    <Popup
      getContainer={null}
      visible={!!Protocol}
      mask={false}
      position="right"
      className="user-popup-wrapper"
      bodyStyle={{ width: '100vw' }}>
      {pageText}
    </Popup>
  );
}
