import wechatInfo from 'constants/wechatInfo';
import { useModal } from 'contexts/useModal';
import { useModalDispatch } from 'contexts/useModal/hooks';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { isWechat } from 'utils';
import CommonModal from '../CommonModal/CommonModal';
const env = process.env.REACT_APP_ENV;

export const redirectToOauth2 = () => {
  const redirectUri = 'http://dac.platformkj.top';
  // const redirectUri = 'http://sinodac-test.intohash.com';
  // const redirectUri = 'https://jbl.intohash.com';

  location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${wechatInfo.appId}&redirect_uri=${redirectUri}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect&forcePopup=true`;
};

export const loginRedirect = () => {
  env === 'dev' ? (location.href = location.origin + '/?code=test&state=STATE') : redirectToOauth2();
};

export default function LoginModal() {
  const nav = useNavigate();
  const { setLoginModal } = useModalDispatch();
  const [{ loginModal }] = useModal();

  const login = useCallback(() => {
    setLoginModal(false);
    if (isWechat()) {
      loginRedirect();
    } else {
      nav('/login?type=login');
    }
  }, [nav, setLoginModal]);

  return (
    <CommonModal
      visible={loginModal}
      actions={[
        [
          { key: 'cancel', text: '取消', className: 'cancel', onClick: () => setLoginModal(false) },
          { key: 'login', text: '登录', className: 'confirm', onClick: login },
        ],
      ]}
      title="登录"
      content="您当前尚未登录, 无法体验全部内容!"
    />
  );
}
