import { Button, Form, Input } from 'antd-mobile';
import { sleep } from 'antd-mobile/es/utils/sleep';
import BaseToast from 'components/BaseToast';
import CityCascader from 'components/CityCascader';
import CountDownButton from 'components/CountDownButton';
import { useUser } from 'contexts/useUser';
import { SubmitType, UserModalContentProps } from 'pages/User/types';
import { useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { Error, Selected } from 'assets/images';
import { useUserDispatch } from 'contexts/useUser/hooks';
import { request } from 'api/index';
import { useSendSmsCode } from 'hooks/useSendSmsCode';
import { useLocalStorage } from 'react-use';
import storages from 'storages';
import { useModalDispatch } from 'contexts/useModal/hooks';
import { SendCodeEnum } from 'types/enum';
const Toast = BaseToast();

const nikeNamePattern = /^([\u4e00-\u9fa5]|[a-zA-Z0-9]){2,16}$/;
// const nikeNamePattern = '/^[\x{4e00}-\x{9fa5}a-zA-Z0-9_-]{2,32}$/u';
export const limitStr = (orgStrVal: string, strNum: number) => {
  let re = '';
  const orgStrLength = orgStrVal.length;
  const strVal = orgStrVal.replace(
    /[\u3002|\uff1f|\uff01|\uff0c|\u3001|\uff1b|\uff1a|\u201c|\u201d|\u2018|\u2019|\uff08|\uff09|\u300a|\u300b|\u3008|\u3009|\u3010|\u3011|\u300e|\u300f|\u300c|\u300d|\ufe43|\ufe44|\u3014|\u3015|\u2026|\u2014|\uff5e|\ufe4f|\uffe5]/g,
    '',
  );
  // const strVal = orgStrVal.replace(/[^\u4E00-\u9FA5|\d|a-zA-Z|\r\n\s,.?!，。？！…—&$=()-+/*{}[\]]|\r\n\s/g, '');
  const strLength = strVal.length;
  const emojiLength = (orgStrLength - strLength) * 2;
  // console.log(emojiLength);
  // eslint-disable-next-line no-control-regex
  const byteLength = strVal.replace(/[^\x00-\xff]/g, '**').length;
  if (byteLength + emojiLength <= strNum) return orgStrVal;
  for (let i = 0, byteNum = 0; i < byteLength + emojiLength; i++) {
    const byte = orgStrVal.charAt(i);
    // eslint-disable-next-line no-control-regex
    if (/[\x00-\xff]/.test(byte)) {
      byteNum++;
    } else if (
      /[\u3002|\uff1f|\uff01|\uff0c|\u3001|\uff1b|\uff1a|\u201c|\u201d|\u2018|\u2019|\uff08|\uff09|\u300a|\u300b|\u3008|\u3009|\u3010|\u3011|\u300e|\u300f|\u300c|\u300d|\ufe43|\ufe44|\u3014|\u3015|\u2026|\u2014|\uff5e|\ufe4f|\uffe5]/.test(
        byte,
      )
    ) {
      byteNum += 4;
    } else {
      byteNum += 2;
    }
    if (byteNum <= strNum) {
      re += byte;
    }
  }
  return re;
};
function ButtonWrapper({ click, sureText = '确认' }: { click: (type: SubmitType) => void; sureText?: string }) {
  return (
    <div className="user-modal-action-wrapper">
      <Button onClick={() => click(SubmitType.cancel)}>取消</Button>
      <Button type="submit" onClick={() => click(SubmitType.ok)}>
        {sureText}
      </Button>
    </div>
  );
}

export function ChangeName({ afterAction }: UserModalContentProps) {
  const [form] = Form.useForm();
  const dispatch = useUserDispatch();
  const [{ nickName }] = useUser();
  const [isError, setError] = useState<boolean>();
  // const [newNickName, setNewNickName] = useState<string>();

  const onFinishHandler = useCallback(
    async (value: any) => {
      console.log(value, 'value===');
      const { nickName } = value ?? {};
      console.log(nickName.length);

      if (!nickName?.trim()) {
        Toast.show({ content: '请输入昵称' });
        setError(true);
        return;
      }
      setError(false);
      // Toast.show({ content: '修改成功', icon: 'success', afterClose: () => afterAction?.({ type: 'success' }) });
      try {
        const res = await request.user.EDIT_SURNAME({
          method: 'PUT',
          data: { surname: nickName },
        });
        console.log('EDIT_SURNAME', res);
        Toast.show({
          content: '修改成功',
          icon: 'success',
          afterClose: () => {
            dispatch.update({ nickName: nickName });
            afterAction?.({ type: 'success' });
          },
        });
        // if (res.code === 200) {
        // }
      } catch (error) {
        Toast.show({ content: '修改失败', icon: 'fail' });
        setError(true);
        console.debug(error);
      }
    },
    [afterAction],
  );
  const ChangeNameBtnClick = useCallback(
    (type: SubmitType) => {
      if (type === SubmitType.ok) {
        console.log('submit');
        // console.log('submit');
        // console.log('submit');
      } else {
        Toast.clear();
        // Toast.clear();
        // Toast.clear();
        afterAction?.({ type: 'cancel' });
      }
    },
    [afterAction],
  );
  const nickNameChange = (value: string) => {
    // console.log(value);
    // setNewNickName(e.target.value);
    // console.log(limitStr(value, 32));
    form.setFieldsValue({
      nickName: limitStr(value, 32),
    });
  };
  return (
    <>
      <div className="old-nickname">原昵称：{nickName ?? '--'}</div>
      <Form
        layout="horizontal"
        onFinish={onFinishHandler}
        footer={<ButtonWrapper click={ChangeNameBtnClick} />}
        form={form}>
        <Form.Item label="" name="nickName">
          <Input className={clsx(isError && 'input-status-error input-transform')} onChange={nickNameChange} />
        </Form.Item>
      </Form>
    </>
  );
}

const IDNumberPattern = /^(\d{6})(\d{4})(\d{2})(\d{2})(\d{3})([0-9]|X)$/;
const namePattern = /^[\u4e00-\u9fa5]{2,6}$/;
const telPattern = /^1((3[0-9])|(4[1579])|(5[0-9])|(6[6])|(7[0-9])|(8[0-9])|(9[0-9]))\d{8}$/;

export function Verified({ afterAction }: UserModalContentProps) {
  const dispatch = useUserDispatch();
  const [isVerifiedName, setIsVerifiedName] = useLocalStorage<boolean>(storages.isVerifiedName);
  // const [{ isVerifiedName }] = useUser();
  const [isError, setError] = useState<{ [x: string]: boolean | undefined }>({});
  let flag = false;

  const onFinishHandler = useCallback(
    async (value: any) => {
      if (flag) return;
      console.log(value, 'value===');
      const { IDNumber, username } = value;
      let error = false;
      let errorMap = {};
      if (!namePattern.test(username)) {
        error = true;
        errorMap = { ...errorMap, username: true };
        Toast.show({ content: '姓名格式不对', icon: 'fail' });
        return;
      }
      if (!IDNumberPattern.test(IDNumber)) {
        error = true;
        errorMap = { ...errorMap, IDNumber: true };
        Toast.show({ content: '身份证号不对', icon: 'fail' });
        return;
      }
      if (error) {
        console.log(errorMap, 'errorMap===');
        setError(errorMap);
        return;
      }
      try {
        flag = true;
        const res = await request.user.VERIFIED({
          method: 'POST',
          data: { idCard: IDNumber, name: username },
        });
        console.log('VERIFIED', res);
        Toast.show({
          content: '实名认证成功',
          icon: 'success',
          afterClose: () => {
            dispatch.update({ IDNumber: IDNumber, isVerifiedName: true });
            setIsVerifiedName(true);
            afterAction?.({ type: 'success' });
            flag = false;
          },
        });
      } catch (err: any) {
        flag = false;
        Toast.show({
          content: `${err?.response?.data?.error?.message}`,
          icon: 'fail',
          afterClose: () => {
            dispatch.update({ IDNumber: IDNumber, isVerifiedName: false });
            setIsVerifiedName(false);
            // afterAction?.({ type: 'cancel' });
          },
        });
      }
    },
    [afterAction],
  );

  const btnClick = useCallback(
    (type: SubmitType) => {
      if (type === SubmitType.ok) {
        console.log('namePattern===');
      } else {
        Toast.clear();
        afterAction?.({ type: 'cancel' });
      }
    },
    [afterAction],
  );

  return (
    <Form onFinish={onFinishHandler} footer={<ButtonWrapper click={btnClick} />}>
      <Form.Item label="真实姓名：" name="username">
        <Input
          className={clsx(!!isError?.username && 'input-status-error input-transform')}
          placeholder="请输入真实姓名"
        />
      </Form.Item>
      <Form.Item label="身份证号：" name="IDNumber">
        <Input
          className={clsx(!!isError?.IDNumber && 'input-status-error input-transform')}
          placeholder="请输入身份证号"
        />
      </Form.Item>
    </Form>
  );
}

enum NextType {
  first,
  next,
  error,
}

export function ChangeTelNumber({ afterAction }: UserModalContentProps) {
  const [form] = Form.useForm();
  const dispatch = useUserDispatch();
  const getSendSmsCode = useSendSmsCode();
  const [okText, setOkText] = useState<string | undefined>('下一步');
  const [isNext, setNext] = useState<NextType>(NextType.first);
  const [isRefresh, setRefresh] = useState<number>(0);
  const [captcha, setCaptcha] = useState<string>('');
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  // const verifyBackCode = 'wwwwww';
  // const verifyOldBackCode = 'aaa';
  const [isError, setError] = useState<{ [x: string]: boolean | undefined }>({});
  const [{ phoneNumber }] = useUser();
  const initialValues = { telNumber: phoneNumber };
  let codeFlag = false;
  const getVerifyCode = useCallback(async () => {
    let telNum;
    let type = SendCodeEnum['验证旧手机号'];
    if (isNext === 0) {
      telNum = form.getFieldValue('telNumber');
    }
    if (isNext === 1) {
      telNum = form.getFieldValue('newTelNumber');
      type = SendCodeEnum['绑定手机号'];
    }

    if (!telNum) return;
    try {
      await getSendSmsCode(telNum, type);
    } catch (err: any) {
      Toast.show({
        content: err?.response?.data?.error?.message ?? `验证码获取失败，请重试`,
      });
      setRefresh((isRefresh) => isRefresh + 1);
    }
  }, [form, getSendSmsCode, isNext]);
  const ChangeTelNumberBtnClick = useCallback(
    (type: SubmitType) => {
      if (type === SubmitType.ok) {
        console.log('submit');
      } else {
        Toast.clear();
        afterAction?.({ type: 'cancel' });
      }
    },
    [afterAction],
  );
  const validate = async () => {
    const value = form.getFieldsValue();
    let error = false;
    let errorMap = {};
    if (isNext === NextType.first && !telPattern.test(value?.telNumber)) {
      error = true;
      errorMap = {
        ...errorMap,
        telNumber: true,
      };
    }
    if (isNext === NextType.first && !IDNumberPattern.test(value?.IDNumber)) {
      error = true;
      errorMap = { ...errorMap, IDNumber: true };
    } else {
      try {
        await request.user.CHECK_ID_CARD({
          method: 'POST',
          data: {
            IdCard: value?.IDNumber,
          },
        });
      } catch (err: any) {
        Toast.show({
          content: err?.response?.data?.error?.message ?? `身份证号与认证身份证号不一致`,
          icon: 'error',
        });
        error = true;
        errorMap = { ...errorMap, IDNumber: true };
      }
    }
    if (isNext === NextType.next && !telPattern.test(value?.newTelNumber)) {
      error = true;
      errorMap = {
        ...errorMap,
        newTelNumber: true,
      };
    }

    if (error) {
      console.log('errorMap', errorMap);

      setError(errorMap);
      return false;
    } else {
      return true;
    }
  };
  const onFinishHandle = useCallback(
    async (value: any) => {
      const { telNumber, newTelNumber, IDNumber, verifyCode, oldVerifyCode } = value;
      console.log(value, 'onFinishHandle===');
      let error = false;
      let errorMap = {};
      if (isNext === NextType.error) {
        // setNext(NextType.next);
        afterAction?.({ type: 'cancel' });
        return;
      }

      if (isNext === NextType.next) {
        // setNext(NextType.error);
        // 确认
        // Toast.show({ content: '修改成功', afterClose: () => afterAction?.({ type: 'success' }) });
        if (!telPattern.test(newTelNumber)) {
          // Toast.show({ content: '手机号格式不对' });
          // return;
          error = true;
          errorMap = {
            ...errorMap,
            newTelNumber: true,
          };
        }
        // if (verifyBackCode.toLocaleLowerCase() !== verifyCode?.toLocaleLowerCase()) {
        //   console.log(verifyBackCode, oldVerifyCode);
        //   error = true;
        //   errorMap = { ...errorMap, oldVerifyCode: true };
        // }

        // afterAction?.({ type: 'fail' });

        if (!error && !codeFlag) {
          try {
            codeFlag = true;
            const res = await request.user.EDIT_PHONE({
              method: 'PUT',
              data: { captcha: captcha, newPhoneNumber: newTelNumber, code: verifyCode },
            });
            console.log('EDIT_PHONE', res);
            Toast.show({
              content: '修改成功',
              afterClose: () => {
                dispatch.update({ phoneNumber: newTelNumber });
                afterAction?.({ type: 'success' });
                codeFlag = false;
              },
            });
          } catch (err: any) {
            Toast.show({
              content: err?.response?.data?.error?.message,
              afterClose: () => {
                setNext(NextType.error);
                afterAction?.({ type: 'fail' });
                codeFlag = false;
              },
            });
          }
        } else {
          setNext(NextType.error);
        }
      }

      if (isNext === NextType.first) {
        // 下一步
        if (!telPattern.test(telNumber)) {
          // Toast.show({ content: '手机号格式不对' });
          // return;
          error = true;
          errorMap = {
            ...errorMap,
            telNumber: true,
          };
        }

        if (!IDNumberPattern.test(IDNumber)) {
          error = true;
          errorMap = { ...errorMap, IDNumber: true };
          // Toast.show({ content: '身份证号不对', icon: 'fail' });
          // return;
        }
        // if (verifyOldBackCode.toLocaleLowerCase() !== oldVerifyCode?.toLocaleLowerCase()) {
        //   console.log(verifyOldBackCode, oldVerifyCode);
        //   error = true;
        //   errorMap = { ...errorMap, oldVerifyCode: true };
        // }
        // success
        if (!error && !codeFlag) {
          try {
            codeFlag = true;
            const res = await request.user.CHECK_PHONE({
              method: 'PUT',
              data: { idCardNumber: IDNumber, code: oldVerifyCode },
            });
            console.log('CHECK_PHONE', res);
            setNext(NextType.next);
            setOkText(undefined);
            setCaptcha(res.captcha);
            codeFlag = false;
          } catch (err: any) {
            console.debug(err);
            setError(errorMap);
            Toast.show({ content: err?.response?.data?.error?.message, icon: 'fail' });
            // Toast.show({ content: '验证码错误', icon: 'fail' });
            afterAction?.({ type: 'fail' });
            codeFlag = false;
          }
        }
      }
      if (error) {
        setError(errorMap);
        return;
      }
    },
    [afterAction, isNext],
  );

  return (
    <Form
      initialValues={initialValues}
      onFinish={onFinishHandle}
      footer={
        isNext === NextType.error ? (
          <div className="user-modal-action-wrapper">
            <Button type="submit">确定</Button>
          </div>
        ) : (
          <ButtonWrapper click={ChangeTelNumberBtnClick} sureText={okText} />
        )
      }
      form={form}>
      <Form.Item hidden={!(isNext === NextType.first)} label="原手机号：" name="telNumber">
        <Input disabled />
      </Form.Item>
      <Form.Item hidden={!(isNext === NextType.next)} label="新手机号：" name="newTelNumber">
        <Input placeholder="" className={clsx(!!isError?.newTelNumber && 'input-status-error input-transform')} />
      </Form.Item>

      <Form.Item hidden={!(isNext === NextType.first)} label="身份证号：" name="IDNumber">
        <Input
          placeholder="请输入身份证号"
          className={clsx(!!isError?.IDNumber && 'input-status-error input-transform')}
        />
      </Form.Item>
      <Form.Item
        hidden={!(isNext === NextType.first)}
        label="验证码"
        name="oldVerifyCode"
        className="verify-code-form-item"
        extra={<CountDownButton isRefresh={isRefresh} firstClick={getVerifyCode} validate={validate} />}>
        <Input maxLength={6} className={clsx(!!isError?.oldVerifyCode && 'input-status-error input-transform')} />
      </Form.Item>

      <Form.Item
        hidden={!(isNext === NextType.next)}
        label="验证码"
        name="verifyCode"
        className="verify-code-form-item"
        extra={<CountDownButton isRefresh={isRefresh} firstClick={getVerifyCode} />}>
        <Input maxLength={6} className={clsx(!!isError?.verifyCode && 'input-status-error input-transform')} />
      </Form.Item>
      {isNext === NextType.error && (
        <div className="flex tel-change-error">
          <span>
            <Error />
          </span>
          <span>新手机号已绑定其他微信，无法修改</span>
        </div>
      )}
    </Form>
  );
}

export function HarvestAddress({ afterAction }: UserModalContentProps) {
  const inputRef = useRef<any>(null);
  const dispatch = useUserDispatch();
  const [isError, setError] = useState<{ [x: string]: boolean | undefined }>({});
  const [{ consigneeName, consigneePhone, consigneeAreaId, consigneeAddress }] = useUser();
  const initialValues = {
    consigneeName: consigneeName,
    consigneePhone: consigneePhone,
    consigneeAreaId: consigneeAreaId?.split('-'),
    consigneeAddress: consigneeAddress,
  };
  useEffect(() => {
    setTimeout(() => {
      inputRef.current.focus();
      inputRef.current.scrollIntoView();
      document.body.scrollTop = document.body.scrollHeight;
    }, 300);
  }, []);
  const onFinishHandle = useCallback(
    async (value: any) => {
      console.log(value, 'onFinishHandle===');
      let error = false;
      let errorMap = {};
      const { consigneeName, consigneePhone, consigneeAreaId, consigneeAddress } = value ?? {};
      if (!consigneeName?.trim()) {
        error = true;
        errorMap = {
          ...errorMap,
          consigneeName: true,
        };
      }
      if (!telPattern || !telPattern.test(consigneePhone)) {
        error = true;
        errorMap = {
          ...errorMap,
          consigneePhone: true,
        };
      }

      if (!consigneeAreaId || consigneeAreaId?.length === 0) {
        error = true;
        errorMap = {
          ...errorMap,
          consigneeAreaId: true,
        };
      }
      if (!consigneeAddress?.trim()) {
        error = true;
        errorMap = {
          ...errorMap,
          consigneeAddress: true,
        };
      }
      if (error) {
        setError(errorMap);
        return;
      }

      try {
        const res = await request.user.CONSIGNEE({
          method: 'PUT',
          data: {
            consigneeName: consigneeName?.trim(),
            consigneePhone: consigneePhone,
            consigneeAreaId: consigneeAreaId.join('-'),
            consigneeAddress: consigneeAddress?.trim(),
          },
        });
        console.log(res);
        dispatch.update({
          consigneeName: consigneeName?.trim(),
          consigneePhone: consigneePhone,
          consigneeAreaId: consigneeAreaId?.join('-'),
          consigneeAddress: consigneeAddress?.trim(),
        });
        afterAction?.({ type: 'success' });
        Toast.show({
          content: '收货信息修改成功',
        });
      } catch (error) {
        console.debug(error);
      }
    },
    [afterAction],
  );

  const btnClick = useCallback(
    (type: SubmitType) => {
      if (type === SubmitType.cancel) {
        Toast.clear();
        afterAction?.({ type: 'cancel' });
        return;
      }
    },
    [afterAction],
  );

  return (
    <div>
      <Form onFinish={onFinishHandle} footer={<ButtonWrapper click={btnClick} />} initialValues={initialValues}>
        <Form.Item required label="收货人姓名" name="consigneeName">
          <Input
            className={clsx(!!isError?.consigneeName && 'input-status-error input-transform')}
            placeholder="请输入收货人姓名"
          />
        </Form.Item>
        <Form.Item required label="收货人手机号：" name="consigneePhone">
          <Input
            className={clsx(!!isError?.consigneePhone && 'input-status-error input-transform')}
            placeholder="请输入手机号："
          />
        </Form.Item>
        <Form.Item required label="收货地区：" name="consigneeAreaId">
          <CityCascader
            extra={<Selected />}
            title="收货地址"
            className="city-cascader-wrapper"
            isError={!!isError?.consigneeAreaId}
          />
        </Form.Item>
        <Form.Item required label="详细地址：" name="consigneeAddress">
          <Input
            className={clsx(!!isError?.consigneeAddress && 'input-status-error input-transform')}
            placeholder=""
            autoFocus
            ref={inputRef}
            onFocus={() => {
              document.body.scrollTop = document.body.scrollHeight;
              inputRef.current.scrollIntoView();
            }}
          />
        </Form.Item>
      </Form>
    </div>
  );
}
