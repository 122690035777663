import { BASE_APIS, BASE_REQ_TYPES, EXPAND_APIS, EXPAND_REQ_TYPES } from './list';
import myServer from './server';
import { IBaseRequest } from './types';
import { spliceUrl, service } from './utils';

function baseRequest({ url, method = 'GET', params = '', errMessage, data, query = '', headers }: IBaseRequest) {
  return service({
    url: spliceUrl(url, query),
    method,
    data,
    params,
    headers,
  }).catch((error) => {
    console.error(error, errMessage);
    return { error: errMessage };
  });
}

myServer.parseRouter('base', BASE_APIS);

Object.entries(EXPAND_APIS).forEach(([key, value]) => {
  myServer.parseRouter(key, value);
});

const request: BASE_REQ_TYPES & EXPAND_REQ_TYPES = Object.assign({}, myServer.base, myServer);

export { baseRequest, request };
