import { API_REQ_FUNCTION, UrlObj } from './types';

export const BASE_APIS: UrlObj = {};

export const USER_APIS = {
  GET_XSRF: '/api/abp/application-configuration',
  GET_WE_CHAT_AUTH: '/api/app/wechatAuthorize/getWechatAuthorize',
  VERIFY_BIND_PHONE: '/api/app/accounts/VerifyIsBindPhoneNumber',
  CREATE_USER_OR_UPDATE: '/api/app/accounts/CreateUserOrUpdateUser',
  TOKEN:
    process.env.NODE_ENV === 'development'
      ? '/connect/sinotoken'
      : 'http://jumpserver.intohash.com:44307/connect/sinotoken',
  MY_PROFILE: '/api/app/accounts/person',
  // MY_PROFILE: '/api/app/user/my-profile',
  EDIT_SURNAME: '/api/app/user/surname',
  CHECK_PHONE: '/api/app/user/phone-number-check',
  EDIT_PHONE: '/api/app/user/phone-number',
  CONSIGNEE: '/api/app/user/consignee',
  VERIFIED: '/api/app/user/user-identity-authentication',
  ORDER_RECORD: '/api/app/order/GetOrders',
  CANCEL_ORDER: '/api/app/order/CancelOrders',
  TRANSFER_RECORD: '/api/app/assets/TransferRecord',
  MY_FOLLOW: '/api/app/assets/MyFollow',
  VALIDATE_REDEEM_CODE: '/api/app/n-fT/validate-redeem-code',
  REDEEM: '/api/app/n-fT/redeem',
  ADDRESS: '/api/app/addresses/address',
  SEND_SMS: '/api/app/sino-sms/sino-send-code',
  // CHECK_SMS: '/api/app/accounts/check-sms-code',
  STATISTICS: '/api/app/user/statistics',
  CHECK_ID_CARD: '/api/app/user/check-user-idcard',
};
export const PER_MUSEUM_APIS = {
  LIBRARY_LIST: '/api/app/n-fT/my-library-list',
  MY_CREATE: '/api/app/assets/MyCreate',
  FAVOURITE_RANKING: '/api/app/assets/FavouriteRanking',
  MY_LIBRARY: '/api/app/assets/MyLibrary',
};
export const NFT_INFO = {
  FIRST_PAGE: '/api/app/assets/FirstPage',
  PROTOCOL_DETAIL: '/api/app/assets/protocol',
  MY_LIBRARY_INFO: '/api/app/assets/MyLibraryInfo',
  SERIES_DETAIL: '/api/app/assets/series',
  ORGANIZATION_DETAIL: '/api/app/accounts/organization',
  FOLLOWS: '/api/app/assets/follows',
  GIVE: '/api/app/assets/give',
  MY_LIBRARY_INFO_BY_ORDER_ID: '/api/app/assets/MyLibraryInfoByOrderId',
  TRANSACTION_INFO: 'api/app/n-fT/transaction-info',
};
export const SALE = {
  SALE_BATCHES: '/api/app/purchase/sale-batches',
  SALE_STATUS: '/api/app/purchase/status',
  PURCHASE: '/api/app/purchase/place-order',
  CREATE_WE_CHAT_PAY: '/api/app/wechatPay/createWechatBill',
  FREE_GET_NFT: '/api/app/wechat/free-get-nft',
};

export const EXPAND_APIS: { [key: string]: UrlObj } = {
  user: USER_APIS,
  personalMuseum: PER_MUSEUM_APIS,
  nftInfo: NFT_INFO,
  sale: SALE,
};

export type BASE_REQ_TYPES = {
  [x in keyof typeof BASE_APIS]: API_REQ_FUNCTION;
};

export type EXPAND_REQ_TYPES = {
  [X in keyof typeof EXPAND_APIS]: {
    [K in keyof typeof EXPAND_APIS[X]]: API_REQ_FUNCTION;
  };
};
